import { getDict } from '@/utils'
import i18n from "i18next";

const Lesson = (self) => ([
  {
    multiple: true,
    generator: (data) => {
      if (!data.lesson) {
        return []
      }

      return data.lesson.map(item => ({
        key: item.lessonId,
        title: (_, i) => `Урок ${i + 1}`,
        data: [
          {
            key: 'description',
            title: i18n.t('projectLessonWeb.description'),
            data: item.description
          },
          {
            key: 'recommendations',
            title: i18n.t('projectLessonWeb.recommendations'),
            data: item.recommendations
          },
          {
            key: 'knowledge',
            title: i18n.t('projectLessonWeb.knowledge'),
            data: getDict(self.props.dict.lessonKnowledge, item.knowledge)
          },
          {
            key: 'unit',
            title: i18n.t('projectLessonWeb.unit'),
            data: item.unit
          },
        ]
      }))
    }
  },
]);

export default Lesson;
