import React, { useEffect, useState } from "react";
import { dictTreeToDict, formatDate, getDict, getDictObjList } from '@/utils';
import { FORMAT_DATE } from '@/config/const';
import Title from "@/pages/CreateProject/CloseReport/components/Title";
import TableBeta from "@/components/TableBeta";
import { Link } from "react-router-dom";
import { Indicator } from "@/components/Indicator";
import cx from "classnames";
import Tooltip from "@/elements/Tooltip";
import { useAppSelector } from "@/utils/typedHooks";
import { KeyIcon, LinkIcon } from "@/pages/CreateProject/BasicNav/Icon";
import { formatNum } from "@/pages/TopReport/frames/FrameList";
import Gantt from "@/pages/CreateProject/Blocks/Gantt";
import { isEmpty, keyBy, orderBy } from "lodash";
import { checkMilestone, getProjectTypeTextNominative, workGroupSort } from '@/pages/CreateProject/Blocks/utils';
import { getStatus, reportWorkLoader } from '@/pages/CreateProject/Status/Edit';
import { linkLoader } from '@/pages/CreateProject/Blocks/WorkList';
import TextHide from '@/components/TextHide';
import FileLink from "@/elements/FileLink";
import i18n from 'i18next';

const FrameProject = ({ projectId, data, pages, setPage, type, isPdf }) => {
  const dict = useAppSelector(state => state.dict);

  const [projectWorks, setProjectWorks] = useState<Work[]>([]);
  const [workLinks, setWorkLinks] = useState<GanttTableLink[]>([]);
  const [statusReport, setStatusReport] = useState(null);

  const projectData = data[0];

  useEffect(() => {
    if (!projectData?.statusReportId) {
      return;
    }

    getStatus(projectData.statusReportId, !isPdf).then(setStatusReport);

    if (isEmpty(projectWorks)) {
      reportWorkLoader(projectData.statusReportId, !isPdf).then(works => setProjectWorks(workGroupSort(works)));
      linkLoader(projectData.id).then(setWorkLinks);
    }
  }, [projectData]);

  const mergeTimingsAndWorks = () => {
    const worksHash = keyBy(projectWorks, 'id');

    return orderBy((statusReport?.timings || [])
      .filter(timing => timing.isOverdue || projectData.reportTimingStatusCode !== 'PLAN')
      .map(timing => ({
        ...(worksHash[timing.projectWorkId] || []),
        ...timing,
      })), 'isOverdue', 'desc');
  };

  const statusTimings = mergeTimingsAndWorks();

  const projectWorksForGantt = () => {
    return projectWorks?.filter(w => w.dateStart && (checkMilestone(w) || w.dateEnd)) || [];
  }

  const columns: any[] = [
    {
      Header: 'Наименование',
      headerStyle: {maxWidth: '200px', minWidth: '200px'},
      style: {maxWidth: '200px'},
      accessor: `name`,
      Cell: ({row}) =>
        <div>
          <div className='project-name-text'>
            {`${row.original.name} (${row.original.typeName})`}
          </div>

          <div className='project-name-link'>
            <Link to={`/${row.original.typeCode.toLowerCase()}/${row.original.id}`} target='_blank'>
              <LinkIcon />
            </Link>
          </div>
        </div>,
    },
    {
      Header: 'Руководитель',
      headerStyle: {maxWidth: '120px'},
      style: {maxWidth: '100px'},
      accessor: `projectLeader`,
      Cell: ({value}) => value,
    },
    {
      Header: 'Куратор',
      headerStyle: {maxWidth: '120px'},
      style: {maxWidth: '120px'},
      accessor: `projectCurator`,
      Cell: ({value}) => value,
    },
    {
      Header: 'SPI',
      headerStyle: {maxWidth: '55px', minWidth: '55px'},
      style: {textAlign: 'center'},
      accessor: `spiMonth`,
      Cell: ({value}) => formatNum(value),
    },
    {
      Header: 'CPI ОКВ',
      headerStyle: {maxWidth: '55px', minWidth: '55px'},
      style: {textAlign: 'center'},
      accessor: `cpiOkbMonth`,
      Cell: ({value}) => formatNum(value),
    },
    {
      Header: 'CPI ДДС',
      headerStyle: {maxWidth: '55px', minWidth: '55px'},
      style: {textAlign: 'center'},
      accessor: `cpiDdsMonth`,
      Cell: ({value}) => formatNum(value),
    },
    {
      Header: 'Сводный статус',
      headerStyle: {maxWidth: '70px', minWidth: '70px'},
      style: {textAlign: 'center'},
      accessor: `reportConsolidateStatusCode`,
      Cell: ({row}) => row.original.reportConsolidateStatusCode && (
        <div className='top-report-indicators'>
          <Indicator className={cx("status-report", row.original.reportConsolidateStatusCode)} />
        </div>
      ),
    },
    {
      Header: () => (
        <div>
          {'Комментарий к статус-отчету'}
          <Tooltip text='Комментарий Руководителя проекта, заполняемый в статус-отчете' placement='bottom' />
        </div>
      ),
      style: {verticalAlign: 'top', maxWidth: '590px', wordWrap: 'break-word'},
      accessor: `reportConsolidateComment`,
      Cell: ({value, row}) => <div>
        {row.original.files && (
          <>
            <b>Документы:</b>
            {row.original.files.map(file => {
              const fileData = file.split('%');
              return (
                <div key={fileData[0]} className="rt-card-subtitle">
                  <FileLink fileId={fileData[0]} fileName={fileData[2]} className='button_Link_borderNone' />
                </div>
              )})}
            <br/>
          </>
        )}
        {value && (<><b>Текст комментария: </b><TextHide text={value} limit={1000} withModal modalTitle='Комментарий к статус-отчету' /></>)}
      </div>,
    }
  ];

  if (['KEY', 'TOP'].includes(type)) {
    columns.push({
        Header: () => (
          <div>
            {'Комментарий корпоративного проектного офиса'}
            <Tooltip text='Комментарий сотрудника департамента корпоративного управления' placement='bottom'/>
          </div>
        ),
        style: { verticalAlign: 'top', maxWidth: '590px', wordWrap: 'break-word' },
        accessor: `reportProjectOfficeComment`,
        Cell: ({ value }) => <TextHide text={value} limit={1000} withModal
                                       modalTitle='Комментарий корпоративного проектного офиса'/>,
      });
  }

  if (['PSR'].includes(type)) {
    columns.push({
      Header: () => (
        <div>
          {'Эскалация'}
          <Tooltip text='Комментарий сотрудника, ответственного за реализацию проекта' placement='bottom'/>
        </div>
      ),
      style: {verticalAlign: 'top', maxWidth: '390px', wordWrap: 'break-word'},
      accessor: `reportEscalationComment`,
      Cell: ({value, row}) => <div>
        {value && (<div>{value}</div>)}
      </div>,
    });
  }


  const titleMap = {
    TOP: `Статус-отчет по портфелю «${i18n.t('IN_TOP')}»`,
    KEY: 'Статус-отчет по портфелю Ключевых проектов',
    PSR: 'Статус-отчет Офиса трансформации'
  }

  return (
    <div className="presentation__page__center top-report">
      <Title className='frame-project-title' pageTitle={titleMap[type]}>
        {`${getProjectTypeTextNominative(projectData.typeCode)} «${projectData.name}»`}
        <span style={{marginLeft: '10px'}}>
          {projectData.isKeyProject && <KeyIcon />}
        </span>
      </Title>

      <div className='top-report-project-data'>
        <div className='top-report-project-data-left'>
          {type === 'PSR' && <div className='top-report-project-data-block'>Статус: {projectData.projectStatusName}</div>}
          <div className='top-report-project-data-block'>Начало проекта: {formatDate(projectData.dateStart, FORMAT_DATE)}</div>
          <div className='top-report-project-data-block'>Окончание проекта: {formatDate(projectData.dateEnd, FORMAT_DATE)}</div>
          <div className='top-report-project-data-block'>Сегмент заказчика: {projectData.segmentNames}</div>
          <div className='top-report-project-data-block'>Сегмент исполнителя: {(getDictObjList(dictTreeToDict(dict.segment), projectData.segmentExecutorIds) || []).map(d => d.name).join(', ')}</div>
          <div className='top-report-project-data-block'>ID {projectData.projectId}</div>
        </div>
        <div className='top-report-project-data-right'>
          {projectData.parentPortfolioName && (
            <div className='top-report-project-data-block'>В составе портфеля: {projectData.parentPortfolioName}</div>
          )}
          {projectData.parentProgramName && (
            <div className='top-report-project-data-block'>В составе программы: {projectData.parentProgramName}</div>
          )}
        </div>
      </div>

      <div className='top-report-table-wrapper'>
        <TableBeta columns={columns}
                   data={data}
                   className={cx('table-responsive custom-scrollbar custom-width-scrollbar', type === 'TOP' ? 'TOP' : 'KEY')} />
      </div>

      <div className='top-report-project-bottom'>
        <div className='top-report-project-gantt'>
          <div className='project-gantt-title'>План-график</div>
          <Gantt
            projectId={projectData.projectId}
            projectBase={projectData.typeCode.toLowerCase()}
            works={projectWorksForGantt()}
            links={workLinks}
            isShowDateEndInit={true}
            scrollContainerClassName='custom-width-scrollbar'
          />
        </div>
        <div className='top-report-project-status custom-scrollbar custom-width-scrollbar'>
          {statusReport?.escalation && (
            <div className='status-card'>
              <div className='status-card-title'>
                Эскалация
              </div>
              <div className='status-card-text'>
                {statusReport.escalationComment}
              </div>
            </div>
          )}
          <div className='status-card'>
            <div className='status-card-title'>
              <div>Сроки</div>
              <Indicator className={cx("status-report", projectData.reportTimingStatusCode)} />
            </div>
            {statusTimings.map(t => (
              <div className='status-card-group' key={t.id}>
                <div className='status-card-text'>
                  {t.projectWorkName}
                </div>
                <div className='status-card-block-group'>
                  <div className='status-card-block'>
                    Тип: {getDict(dict.workType, t.typeId)}
                  </div>
                  <div className='status-card-block'>
                    Отклонение: {t.deviation}%
                  </div>
                  <div className='status-card-block'>
                    Начало: {formatDate(t.dateStart, FORMAT_DATE)}
                  </div>
                  <div className='status-card-block'>
                    Окончание: {formatDate(t.dateEnd, FORMAT_DATE)}
                  </div>
                </div>
                {t.comment && (
                  <div className='status-card-text'>
                    Комментарий: {t.comment}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className='status-card'>
            <div className='status-card-title'>
              <div>Бюджет</div>
              <Indicator className={cx("status-report", projectData.reportBudgetStatusCode)} />
            </div>
            {(!!statusReport?.budgetOkbDeviation || !!statusReport?.budgetDdsDeviation
              || !!statusReport?.budgetOpexDeviation || !!statusReport?.budgetFotDeviation
              || (!!statusReport?.budgetComment && projectData.reportBudgetStatusCode !== 'PLAN')) && (
              <div className='status-card-group'>
                <div className='status-card-block-group'>
                  {!!statusReport?.budgetOkbDeviation && (
                    <div className='status-card-block'>
                      Отклонение ОКВ: {statusReport.budgetOkbDeviation}%
                    </div>
                  )}
                  {!!statusReport?.budgetDdsDeviation && (
                    <div className='status-card-block'>
                      Отклонение ДДС: {statusReport.budgetDdsDeviation}%
                    </div>
                  )}
                  {!!statusReport?.budgetOpexDeviation && (
                    <div className='status-card-block'>
                      Отклонение OPEX: {statusReport.budgetOpexDeviation}%
                    </div>
                  )}
                  {!!statusReport?.budgetFotDeviation && (
                    <div className='status-card-block'>
                      Отклонение FOT: {statusReport.budgetFotDeviation}%
                    </div>
                  )}
                </div>
                {statusReport.budgetComment && (
                  <div className='status-card-text'>
                    Комментарий: {statusReport.budgetComment}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FrameProject;