const AgreementTeamBlock = (self) => ([
  {
    title: "Подразделения",
    data: (data) => data.units
  },
  {
    title: "Поставщик / Подрядчик / Вендор",
    data: (data) => data.provider
  },
])

export default AgreementTeamBlock;
