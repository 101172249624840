import './style.scss';

import { isEmpty, keys } from "lodash";
import React, { useState } from 'react';
import { Modal } from "react-bootstrap";

import ButtonRt from "@/components/Helper/ButtonRt";
import ButtonSimple from '@/elements/ButtonSimple';
import TableOrdersFromR12 from "@/pages/CreateProject/Blocks/BudgetPrediction/TableOrdersFromR12";
import services from "@/services";
import BudgetPredictionTableFilter from "@/pages/CreateProject/Blocks/Components/BudgetPredictionTableFilter";
import { formatDate } from "@/utils";
import { FORMAT_MONTH_YEAR } from "@/config/const";

const GetOrderFromR12 = ({
  projectVersionId,
  getWorkId,
  onSelect,
  onReject,
}: {
  projectVersionId: number,
  getWorkId: Function,
  onSelect: Function,
  onReject: Function,
}) => {
  const [workId, setWorkId] = useState(null);
  const [orders, setOrders] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [orderFilter, setOrderFilter] = useState(null);

  const filteredOrderList = () => {
    if (!orderFilter) {
      return orders;
    }
    return orders.filter(value => {
      let isPass = true;
      keys(orderFilter).forEach(key => {
        if (!orderFilter[key]) {
          return;
        }
        if (!value[key]) {
          isPass = false;
          return;
        }
        if (key === 'completionDate' || key === 'dueDate') {
          isPass = formatDate(value[key], FORMAT_MONTH_YEAR) === orderFilter[key] ? isPass : false;
          return;
        }
        isPass = value[key] === orderFilter[key] ? isPass : false;
      })
      return isPass;
    })
  }

  const onClick = () => {
    getWorkId()
      .then(setWorkId)
      .then(() => services.get(`/budget/prediction/order/project/${projectVersionId}/r12`, null, { isShowLoad: true }))
      .then(data => data.map((ord, index) => {
        return {
          ...ord,
          key: [ord.r12code, ord.sysNumber, ord.completionDate, ord.dueDate, ord.okb, ord.dds, `ind-${index}`].join('_'),
        };
      }))
      .then(setOrders)
      .then(() => setIsShow(true))
      .catch(e => { });
  };

  const onOk = () => {
    const selected = selectedOrders();
    if (isEmpty(selected)) {
      throw new Error('Не выбран ни один заказ');
    }

    onSelect(workId, selected);
    cancel();
    setOrderFilter(null);
  };

  const onCancel = () => {
    cancel();
    onReject(workId);
    setOrderFilter(null);
  };

  const cancel = () => {
    setOrders([]);
    setIsShow(false);
  };

  const onToggleOrder = (key) => {
    setOrders(orders.map(o => ({
      ...o,
      isChecked: o.key === key ? !o.isChecked : o.isChecked
    })));
  };

  const isAllChecked = () => !isEmpty(filteredOrderList()) && filteredOrderList().every(({ isChecked }) => isChecked);

  const onToggleAll = () => {
    const isChecked = !isAllChecked();
    setOrders(filteredOrderList().map(o => ({
      ...o,
      isChecked,
    })));
  };

  const selectedOrders = () => {
    return filteredOrderList().filter(o => o.isChecked);
  };

  return (
    <>
      <ButtonSimple children="Добавить из R12" onClick={onClick} />

      <Modal show={isShow} onHide={onCancel} size="xl" dialogClassName="orders-r12-modal">
        <Modal.Header closeButton>
          <Modal.Title>Выбор заказов из R12</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BudgetPredictionTableFilter orders={orders} onChange={(filter) => setOrderFilter(filter)}/>
          <TableOrdersFromR12 data={filteredOrderList()}
            isCheckedAll={isAllChecked()}
            onToggleOrder={onToggleOrder}
            onToggleAll={onToggleAll} />
        </Modal.Body>
        <Modal.Footer>
          <>
            <ButtonRt type="outline" onClick={onCancel}>Отменить</ButtonRt>
            <ButtonRt disabled={isEmpty(selectedOrders())} onClick={onOk}>Добавить</ButtonRt>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GetOrderFromR12;