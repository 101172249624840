import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import TextArea from '@/elements/TextArea';
import { isEmpty } from 'lodash';
import ButtonRt from "@/components/Helper/ButtonRt";

const ReworkComment = ({
  isShow,
  onOk,
  hide,
}:{
  isShow: boolean,
  onOk: Function,
  hide: Function,
}) => {
  const [comment, setComment] = useState(null);

  const isError = isEmpty(comment);

  const ok = () => {
    if (isError) {
      return;
    }

    onOk(comment);
    setComment(null);
    hide();
  }

  return (
    <React.Fragment>
      <Modal show={isShow} onHide={() => hide()} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Комментарий к доработке</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextArea
            label="Комментарий"
            value={comment}
            onChange={e => setComment(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <ButtonRt type="outline" onClick={() => hide()}>Отмена</ButtonRt>
          <ButtonRt disabled={isError} onClick={ok}>Отправить</ButtonRt>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default ReworkComment;
