import React, { useEffect, useState } from "react";
import services from "@/services";

const getDetailedUserByLogin = (login) => services.get(`/user/loadUserByUserLogin/${login}`,
  {
    ignoreError: true,
    disableCheckStatus: [403, 404, 500]
  });

const FunctionCodeBadge = ({userLogin, className}: { userLogin?: string, className?: string }) => {
  const [detailedInfo, setDetailedInfo] = useState(null);

  useEffect(() => {
    if (userLogin) {
      getDetailedUserByLogin(userLogin).then(setDetailedInfo).catch(_ => {
      });
    }
  }, [userLogin]);

  return <div className={className}>{detailedInfo?.functionCode
    ?  detailedInfo?.functionCode.split(',').map(value => <div>{value.trim()}</div>)
    : 'Не указано'}</div>;
};

export default FunctionCodeBadge;