import React from "react";
import cx from 'classnames'
import { getRoundFormat } from '@/pages/CreateProject/Budget/util';

const BudgetDifferenceTable = ({ data, tableHash }) => {
  return (
    <div className="flex-space-between">
      <div className={cx("prediction-table__wrapper")}>
        <table className="table table-hove table-felix budget-table__container contracted-budget-table difference-table">
          <thead className="budget-table__head">
            <tr>
              <td className="budget-table__field-head">Тип бюджета</td>
              <td className="budget-table__field-head left-border">План</td>
              <td className="budget-table__field-head left-border">Справочно факт за предыдущие месяца</td>
              <td className="budget-table__field-head left-border">Прогноз</td>
              <td className="budget-table__field-head left-border">Разница</td>
            </tr>
          </thead>
          <tbody>
            {data.differenceList.map((listItem, i) => {
              return (
                <tr key={listItem.id + tableHash + i} className="tableEditRow">
                  <td>{listItem.budgetType}</td>
                  <td className="left-border">{getRoundFormat(listItem.plan)}</td>
                  <td className="left-border">{getRoundFormat(listItem.fact)}</td>
                  <td className="left-border">{getRoundFormat(listItem.prediction)}</td>
                  <td className="left-border">{getRoundFormat(listItem.difference)}</td>
                </tr>
              )
            }
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default BudgetDifferenceTable;

