import i18n from "i18next";
import { compact } from 'lodash';
import React from 'react';
import Table from '@/components/Table';
import { FORMAT_MONTH_YEAR } from '@/config/const';
import Checkbox from '@/elements/Checkbox';
import { formatDate } from '@/utils';

const rowClasses = (row) => {
  return row.status === 'Завершен' ? 'report-warning' : null;
};

const TableOrdersFromR12 = ({
  data, isCheckedAll, onToggleOrder, onToggleAll
}) => {
  const detailColumns = compact([
    {
      dataField: 'sysNumber',
      text: i18n.t('budgetPredictionOrderWeb.docNumber'),
      sort: true,
      headerStyle: () => {
        return { minWidth: '110px' };
      },
      formatExtraData: {
        onToggleOrder
      },
      headerFormatter: ({ text }, _, { sortElement }) => {
        return (
          <>
            <div className="flex-inline-center-vertical">
              <Checkbox
                checked={isCheckedAll}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onToggleAll();
                }}
                tooltip="Выделить все заказы для добавления"
              />
              {text}
            </div>
            {sortElement}
          </>
        );
      },
      formatter: (cell, row, _, { onToggleOrder }) => {
        return (
          <div className="flex-inline-center-vertical">
            <Checkbox
              checked={row.isChecked || false}
              onChange={() => onToggleOrder(row.key)}
              tooltip="Выбрать заказ для добавления"
            />
            {cell}
          </div>
        );
      },
    },
    {
      dataField: 'r12code',
      text: i18n.t('budgetPredictionOrderWeb.r12code'),
      sort: true,
      formatter: (cell, row) => cell ? `${cell} (${row.r12name})` : '',
    },
    {
      dataField: 'description',
      text: i18n.t('budgetPredictionOrderWeb.subjectDescription'),
      sort: true,
      headerStyle: () => {
        return { maxWidth: '227px' };
      },
      style: () => {
        return { maxWidth: '227px' };
      },
    },
    {
      dataField: 'contractor',
      text: i18n.t('budgetPredictionOrderWeb.contractor'),
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Статус',
      sort: true,
    },
    {
      dataField: 'signingDate',
      text: i18n.t('budgetPredictionOrderWeb.signingDate'),
      sort: true,
      formatter: cell => formatDate(cell, FORMAT_MONTH_YEAR)
    },
    {
      dataField: 'completionDate',
      text: i18n.t('budgetPredictionOrderWeb.completionDate'),
      sort: true,
      formatter: cell => formatDate(cell, FORMAT_MONTH_YEAR)
    },
    {
      dataField: 'dueDate',
      text: i18n.t('budgetPredictionOrderWeb.dueDate'),
      sort: true,
      formatter: cell => formatDate(cell, FORMAT_MONTH_YEAR)
    },
    {
      dataField: 'okb',
      text: i18n.t('budgetPredictionOrderWeb.okb'),
      sort: true,
    },
    {
      dataField: 'dds',
      text: i18n.t('budgetPredictionOrderWeb.dds'),
      sort: true,
    },
  ]);

  return (
    <Table
      wrapperClasses="orders-r12-table sticky-horizontal-first-col"
      keyField='key'
      data={data}
      columns={detailColumns}
      rowClasses={rowClasses}
    />
  );
};

export default TableOrdersFromR12;
