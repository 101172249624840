import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { formatDateWithFormat } from '@/utils';
import { getPathByType } from '@/utils/project';
import { keyBy, cloneDeep, isEqual } from 'lodash';
import cx from 'classnames';

import { useDeviceContext } from '@/context/DeviceContext';

import Checkbox from '@/elements/Checkbox';

import DashboardCard from '@/pages/Dashboard2/components/DashboardCard';
import ProjectIcon from '@/pages/Dashboard2/components/ProjectIcon';
import WorkList from '@/pages/Dashboard2/WorkList';
import { formatMuiltiline } from '@/pages/Dashboard2/utils'
import Table from '@/components/Table';
import service from '@/services';
import StatusIndicator from '@/components/Indicator';
import { LinkIcon } from "@/pages/CreateProject/BasicNav/Icon";

const getWorkIntegrationXls = (props) => service.postDownload(`/work/integration/xls`, props)

const getDetailColumns = ({
  updateCurrentProjectSelect,
  openItem
}) => ([
  {
    dataField: 'typeCode',
    text: 'Тип',
    formatter: cell => <ProjectIcon type={cell} />
  },
  {
    dataField: 'projectName',
    text: 'Название',
    headerStyle: { width: '100%' },
    formatter: (cell, row) => {
      return (
        <div style={{ marginLeft: row.depth * 2 + 'rem', display: 'flex', alignItems: 'center' }}>
          <Checkbox
            className="dashboard-checkbox radio no-margin-left"
            checked={row.isCheckedWork || false}
            onChange={() => updateCurrentProjectSelect(row)}
          />
          <div className="flex-grow-1">
            <Link target="_blank" to={`/${getPathByType(row.typeCode)}/${row.projectVerId}`}>
              {cell}
            </Link>
          </div>
          {row.projectList
            && row.projectList.length
            && <div className={cx("arrow-list-item", { 'open': row.isOpen })} onClick={() => openItem(row.id)}></div>}
        </div>
      )
    }
  },
  {
    dataField: 'stageName',
    text: 'Стадия',
  },
  {
    dataField: 'leaderProject',
    text: 'Руководитель',
    classes: 'nowrap',
    formatter: formatMuiltiline,
  },
  {
    dataField: 'reportStatusId',
    text: 'Сводный статус',
    classes: 'with-indicator',
    formatter: cell => (<StatusIndicator value={cell} />),
  },
  {
    dataField: 'reportDateCreate',
    text: 'Дата публикации статус-отчета',
    classes: 'nowrap',
    formatter: (cell, row) => cell === null ? null : (
      <Link className="dashboard-report-link" target="_blank" to={`/${getPathByType(row.typeCode)}/${row.projectVerId}/status`}>
        <span>{row.reportPublished ? formatDateWithFormat(row.reportDateUpdate) : '-'}</span>
        <LinkIcon className="dashboard-report-link_icon" />
      </Link>
    ),
    excel: {
      formatter: (cell) => formatDateWithFormat(cell),
      width: 15,
    },
  },
  {
    dataField: 'reportStatusComment',
    text: 'Комментарий к сводному статусу',
    classes: 'nowrap',
    formatter: formatMuiltiline,
  },
]);

const createProjectTree = (list) => {
  const projectHash = keyBy(cloneDeep(list), 'id')

  list.forEach(({ id, programId, portfolioId }) => {
    let isChild = false;
    if (programId && projectHash[programId]) {
      projectHash[programId].projectList ||= []
      projectHash[programId].projectList.push(projectHash[id]);
      isChild = true;
    }

    // в портфель суем проект только если у него нет программы,
    // или порфтель программы отличается от портфеля проекта
    if (portfolioId
      && projectHash[portfolioId]
      && (programId === null
        || (projectHash[programId]
          && projectHash[programId].portfolioId !== portfolioId))) {
      projectHash[portfolioId].projectList ||= []
      projectHash[portfolioId].projectList.push(projectHash[id]);
      isChild = true;
    }

    projectHash[id].isChild = isChild;
  });

  // @ts-ignore ts-migrate(2550) FIXME: Property 'values' does not exist on type 'ObjectCo... Remove this comment to see the full error message
  return Object.values(projectHash).filter(({ isChild }) => isChild !== true);
}

const getProjectListByTree = (list, depth = 0, acc = []) => {
  list.forEach(item => {
    acc.push({
      ...item,
      depth
    })
    if (item.isOpen && item?.projectList?.length) {
      getProjectListByTree(item.projectList, depth + 1, acc);
    }

  })

  return acc;
}

const mapKey = (list, key) => list.map(item => item[key]);

const isUpdateProjectList = (p1, p2) => {
  return !isEqual(mapKey(p1, 'id'), mapKey(p2, 'id'))
}

export const getProjectTypeText = projectType => ({
  PROJECT: 'проекта',
  PROGRAM: 'программы',
  PORTFOLIO: 'портфеля'
}[projectType])

const ProjectWorkList = ({ projectList = [] }) => {
  const { isMobile } = useDeviceContext()
  const [data, setData] = useState([]);
  const [currentProject, setCurrentProject] = useState(null);
  const [isOnlyInteg, setIsOnlyInteg] = useState(true);
  const dataFiltered = data.filter(({ projectTypeCode }) => !isOnlyInteg || projectTypeCode === 'PR_INTEGRATION')
  const dataReach = getProjectListByTree(createProjectTree(dataFiltered));
  const projects = {
    projectVerId: dataFiltered.map(item => item.projectVerId)
  };

  const updateCurrentProjectSelect = (row) => {
    setData((list) => list.map(item => {
      return {
        ...item,
        isCheckedWork: !row || item.projectVerId !== row.projectVerId ? false : !item.isCheckedWork
      }
    }))

    setCurrentProject(!row || row.isCheckedWork ? null : row)
  };
  const openItem = (id) => {
    setData((list) => list.map(item => {
      if (item.id === id) {
        return {
          ...item,
          isOpen: !item.isOpen
        }
      }

      return item;
    }))
  }
  const detailColumns = getDetailColumns({
    updateCurrentProjectSelect,
    openItem
  });

  useEffect(() => {
    if (!isUpdateProjectList(data, projectList)) {
      return;
    }
    setData([...projectList]);
  }, [projectList]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateCurrentProjectSelect(null);
  }, [isOnlyInteg]);

  const renderCheckbox = (className?: string) => (
    <Checkbox
      className={cx('dashboard-checkbox title-right', className)}
      checked={isOnlyInteg}
      onChange={() => setIsOnlyInteg(!isOnlyInteg)}
      title="Только интеграционные проекты"
    />
  );

  const renderProjectMobile = (project) => (
    <React.Fragment key={project.id}>
      <div className={cx('projectWorkList_project', { 'projectWorkList_project-open': project.isOpen })}>
        <div className={cx('projectWorkList_project__wrapper', { 'projectWorkList_project__wrapper-checked': !!project.isCheckedWork })}>
          <Checkbox
            className={cx('dashboard-checkbox', 'radio', 'projectWorkList_project__checkbox')}
            checked={!!project.isCheckedWork}
            onChange={() => updateCurrentProjectSelect(project)}
          />
          <div className="projectWorkList_project__content">
            <div className="projectWorkList_project__title">
              <div className="projectWorkList_project__type">{project.typeName}</div>
              <Link className="projectWorkList_project__name" target="_blank" to={`/${getPathByType(project.typeCode)}/${project.projectVerId}`}>
                {project.projectName}
              </Link>
              {project.projectList?.length && (
                <div
                  className={cx('arrow-list-item', 'projectWorkList_project__icon', { 'open': project.isOpen })}
                  onClick={() => openItem(project.id)}
                />
              )}
            </div>
            {project.stageName && <div className="projectWorkList_project__stage">{project.stageName}</div>}
            <div className="projectWorkList_project__leader">{formatMuiltiline(project.leaderProject)}</div>
            {(!!project.reportStatusId || project.reportStatusId === 0) && (
              <div className="projectWorkList_project__status">
                <StatusIndicator value={project.reportStatusId} />
                <span className="projectWorkList_project__status-title">Сводный статус</span>
              </div>
            )}
            {project.reportDateCreate && (
              <Link
                className={cx('dashboard-report-link', 'projectWorkList_project__date')}
                target="_blank"
                to={`/${getPathByType(project.typeCode)}/${project.projectVerId}/status`}
              >
                <span>{project.reportPublished ? formatDateWithFormat(project.reportDateUpdate) : '-'}</span>
                <LinkIcon className="dashboard-report-link_icon" />
              </Link>
            )}
          </div>
        </div>
      </div>
      {project.isOpen && (
        <div className="projectWorkList_project__children">
          {project.projectList?.map(renderProjectMobile)}
        </div>
      )}
    </React.Fragment>
  );

  const renderMobileTemplate = () => (
    <>
      {renderCheckbox('dashboard__projectWorkList_checkboxMobile')}
      <div className="dashboard__projectWorkList_projects">
        {dataReach.map(renderProjectMobile)}
      </div>
    </>
  );

  const downloadXls = () => getWorkIntegrationXls(projects);

  return (
    <div className="dashboard__card-union">
      <DashboardCard withLine
        title="Список проектов"
        titleMiddle={
          <button className="link dashboard__card-title__reset" onClick={downloadXls}>Выгрузить в Excel</button>
        }
        titleRight={!isMobile && renderCheckbox()}
      >
        {isMobile
          ? renderMobileTemplate()
          : (
            <Table
              wrapperClasses='dashboard__projectWorkList_tableContainer'
              keyField='id'
              data={dataReach}
              columns={detailColumns}
              big
            />
          )
        }
      </DashboardCard>
      {currentProject && (
        <DashboardCard withLine
          title={`Статус по блокам работ ${getProjectTypeText(currentProject.typeCode)} «${currentProject.projectName}»`}
        >
          <WorkList currentProject={currentProject} />
        </DashboardCard>
      )}

    </div>
  )
}

export default ProjectWorkList;
