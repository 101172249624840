import i18n from "i18next";
import { compact, isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import Table from '@/components/Table';
import { FORMAT_MONTH_YEAR } from '@/config/const';
import Checkbox from '@/elements/Checkbox';
import { R12Icon } from "@/pages/CreateProject/BasicNav/Icon";
import { formatMuiltiline } from "@/pages/Dashboard2/utils";
import { formatDate, getDict } from '@/utils';
import { useAppSelector } from '@/utils/typedHooks';

const sortMonthYear = (a, b, order) => {
  const orderType = order === 'asc' ? 1 : -1;

  return moment(a.localDate).diff(moment(b.localDate)) * orderType;
};

const BudgetPredictionOrderTable = ({
  data,
  projectBase,
  projectId,
  onToggleOrder = undefined,
  onToggleAll = undefined,
  isShowRemove,
  withWork = false,
  works = []
}) => {
  const dict = useAppSelector(state => state.dict);
  const isCheckedAll = data?.every(({ isChecked }) => isChecked);

  const detailColumns = compact([
    {
      dataField: 'workName',
      text: i18n.t('budgetPredictionOrderWeb.workName'),
      sort: true,
      hidden: !withWork,
      headerStyle: { minWidth: '120px' },
      formatExtraData: works,
      formatter: (cell, row, rowIndex, extraData) => {
        const work = extraData.find(w => w.id === row.workId || w.workId === row.workId);

        if (work) {
          return <Link to={`/${projectBase}/${projectId}/work/${work.id}`}>{work.name}</Link>;
        }

        return row.workId && (
          <Link to={`/${projectBase}/${row.workProjectVersionId}/work/${row.workId}`}>{row.workName}</Link>
        );
      }
    },
    {
      dataField: 'num',
      text: i18n.t('budgetPredictionOrderWeb.num'),
      sort: true,
      headerStyle: { minWidth: '130px', left: withWork ? '120px' : '0' },
      style: { left: withWork ? '120px' : '0' },
      formatExtraData: {
        isShowRemove,
        onToggleOrder,
        works,
      },
      headerFormatter: ({ text }, _: any, { sortElement }) => {
        return (
          <>
            <div className="flex-inline-center-vertical">
              {isShowRemove && !isEmpty(data) && (
                <Checkbox
                  checked={isCheckedAll}
                  onChange={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onToggleAll();
                  }}
                  tooltip="Выделить все заказы для удаления"
                />
              )}
              {text}
            </div>
            {sortElement}
          </>
        );
      },
      formatter: (cell, row, _: any, { isShowRemove, onToggleOrder, works }) => {
        if (!row.id) {
          return null;
        }

        const work = works.find(w => w.id === row.workId || w.workId === row.workId);
        return (
          <div className="flex-inline-center-vertical">
            {isShowRemove && (
              <Checkbox
                checked={row.isChecked || false}
                onChange={() => onToggleOrder(row.id)}
                tooltip="Выделить заказ для удаления"
              />
            )}
            {work ? (
              <Link to={`/${projectBase}/${projectId}/work/${work.id}/prediction/${row.id}`}>Заказ {cell}</Link>
            ) : (
              <Link to={`/${projectBase}/${projectId}/work/${row.workId}/prediction/${row.id}`}>Заказ {cell}</Link>
            )}
            {row.isFromR12 && (
              <R12Icon isCorrected={row.isR12Corrected}/>
            )}
          </div>
        );
      },
    },
    {
      dataField: 'r12code',
      text: i18n.t('budgetPredictionOrderWeb.r12code'),
      sort: true,
      headerStyle: { left: withWork ? 'unset' : '130px' },
      style: { left: withWork ? 'unset' : '130px' },
      formatter: (cell, row) => cell ? formatMuiltiline(`${cell}\n(${row.r12name})`) : '',
    },
    {
      dataField: 'typeId',
      text: i18n.t('budgetPredictionOrderWeb.typeId'),
      sort: true,
      formatter: cell => getDict(dict.budgetPredictionOrderType, cell),
    },
    {
      dataField: 'transferItem',
      text: i18n.t('budgetPredictionOrderWeb.transferItem'),
      sort: true,
    },
    {
      dataField: 'docNumber',
      text: i18n.t('budgetPredictionOrderWeb.docNumber'),
      sort: true,
    },
    {
      dataField: 'contractor',
      text: i18n.t('budgetPredictionOrderWeb.contractor'),
      sort: true,
    },
    {
      dataField: 'investCategoryId',
      text: i18n.t('budgetPredictionOrderWeb.investCategoryId'),
      sort: true,
      formatter: (cell, row) => row.investCategoryName || getDict(dict.investCategory, cell),
    },
    {
      dataField: 'okb',
      text: i18n.t('budgetPredictionOrderWeb.okb'),
      sort: true,
      headerStyle: { minWidth: '105px' },
    },
    {
      dataField: 'completionDate',
      text: i18n.t('budgetPredictionOrderWeb.completionDate'),
      sort: true,
      sortFunc: sortMonthYear,
      formatter: cell => formatDate(cell, FORMAT_MONTH_YEAR),
      headerStyle: { minWidth: '120px' },
    },
    {
      dataField: 'dds',
      text: i18n.t('budgetPredictionOrderWeb.dds'),
      sort: true,
      headerStyle: { minWidth: '105px' },
    },
    {
      dataField: 'dueDate',
      text: i18n.t('budgetPredictionOrderWeb.dueDate'),
      sort: true,
      sortFunc: sortMonthYear,
      formatter: cell => formatDate(cell, FORMAT_MONTH_YEAR)
    },
    {
      dataField: 'transferOkbDate',
      text: i18n.t('budgetPredictionOrderWeb.transferOkbDate'),
      sort: true,
      sortFunc: sortMonthYear,
      formatter: cell => formatDate(cell, FORMAT_MONTH_YEAR)
    },
    {
      dataField: 'transferDdsDate',
      text: i18n.t('budgetPredictionOrderWeb.transferDdsDate'),
      sort: true,
      sortFunc: sortMonthYear,
      formatter: cell => formatDate(cell, FORMAT_MONTH_YEAR)
    },
  ]);

  return (
    <Table
      wrapperClasses="sticky-horizontal"
      keyField='id'
      data={data ?? []}
      columns={detailColumns}
    />
  );
};

export default BudgetPredictionOrderTable;
