import React from 'react';
import Select from '@/elements/Select';
import { getDictForSelect } from '@/utils'
import { useAppSelector } from '@/utils/typedHooks';

export const formatWithCodeName = (item) => item ? `${item.code} - ${item.name}` : null;

const BudgetCfoSelect = ({ value, onChange }) => {
  const cfoList = useAppSelector(state =>
    getDictForSelect(state.dict.cfo, formatWithCodeName));

  return (
    <Select
      titleClassName="dashboard__select mini cfo-select__container"
      options={cfoList}
      label="ЦФО"
      value={value}
      onChange={onChange}
      placeholder="Выберите ЦФО"
      isClearable={true}
    />
  )
}

export default BudgetCfoSelect;
