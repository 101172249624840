import React, { Fragment, useEffect, useState } from "react";
import { dictTreeToDict, formatDate, getDictByCode, getDictObj, prevent } from "@/utils";
import { FORMAT_DATE } from '@/config/const';
import Title from "@/pages/CreateProject/CloseReport/components/Title";
import TableBeta from "@/components/TableBeta";
import { Link } from "react-router-dom";
import { Indicator } from "@/components/Indicator";
import cx from "classnames";
import Tooltip from "@/elements/Tooltip";
import StatusConsolidate from "@/pages/TopReport/components/StatusConsolidate";
import Select from "@/elements/Select";
import { useStateWithKeyMemorizing } from '@/utils/hooks';
import { useAppSelector } from "@/utils/typedHooks";
import { intersection, isEmpty, uniq } from "lodash";
import Input from "@/elements/Input";
import { LinkIcon } from "@/pages/CreateProject/BasicNav/Icon";
import { getProjectTypeTextNominative } from "@/pages/CreateProject/Blocks/utils";
import { topReportExcelExport } from "@/pages/TopReport/components/topReportExcelExport";
import TextHide from '@/components/TextHide';
import FileLink from "@/elements/FileLink";
import i18n from 'i18next';

export const formatNum = (num) => num ? +(Math.round(num * 100) / 100).toFixed(2) : 0;

const FrameList = ({projectId, data, pages, setPage, type, isPdf, pageNumber, uniqReportKey}) => {
  const dict = useAppSelector(state => state.dict);
  const [filter, setFilterByKey, setFilter, restoreFilter] = useStateWithKeyMemorizing({
    segment: [],
    reportStatus: [],
    projectName: '',
    projectStatus: [],
    direction: []
  }, `${uniqReportKey}@${pageNumber}`);
  const [localData, setLocalData] = useState(data.map((item, index) => ({index: index + 1, ...item})));

  useEffect(() => {
    restoreFilter();
  }, []);

  useEffect(() => {
    setLocalData(data.filter(item => {
      if (!isEmpty(filter.segment)) {
        if (isEmpty(intersection(filter.segment, item.segmentIds))) {
          return false;
        }
      }

      if (!isEmpty(filter.reportStatus)) {
        if (!filter.reportStatus.includes(getDictByCode(dict.reportStatus, item.reportConsolidateStatusCode)?.id)) {
          return false;
        }
      }

      if (!isEmpty(filter.projectName)) {
        if (!item.name.toUpperCase().includes(filter.projectName.toUpperCase())) {
          return false;
        }
      }

      if (!isEmpty(filter.direction)) {
        if (!filter.direction.includes(getDictByCode(dict.projectDirection, item.projectDirectionCode)?.id)) {
          return false;
        }
      }

      if (!isEmpty(filter.projectStatus)) {
        if (!filter.projectStatus.includes(getDictByCode(dict.status, item.projectStatusCode)?.id)) {
          return false;
        }
      }

      return true;
    }).map((item, index) => ({
      index: index + 1,
      ...item,
    })));
  }, [data, filter]);

  const segmentForSelect = uniq<number>(data
    .flatMap(item => item.segmentIds))
    .filter(s => !!s)
    .map(s => getDictObj(dictTreeToDict(dict.segment), s))
    .map(s => ({
      label: s.name,
      value: s.id,
    }));

  const reportStatusForSelect = uniq<string>(data
    .map(item => item.reportConsolidateStatusCode))
    .filter(s => !!s)
    .map(s => getDictByCode(dict.reportStatus, s))
    .map(s => ({
      label: s.name,
      value: s.id,
    }));

  const reportProjectStatusForSelect = uniq<string>(data
    .map(item => item.projectStatusCode))
    .filter(s => !!s)
    .map(s => getDictByCode(dict.status, s))
    .map(s => ({
      label: s.name,
      value: s.id,
    }));

  const reportDirectionForSelect = uniq<string>(data
    .map(item => item.projectDirectionCode))
    .filter(s => !!s)
    .map(s => getDictByCode(dict.projectDirection, s))
    .map(s => ({
      label: s.name,
      value: s.id,
    }));

  const nameColGenerator = (row) => {
    return (
      <div>
        <div className='mb-1'>{row.name}</div>
        <div className='mb-1'><b>Тип:</b> {row.typeName}</div>
        {type === 'PSR' && <div className='mb-1'><b>Статус:</b> {row.projectStatusName}</div>}
        <div className='mb-1'><b>РП:</b> {row.projectLeader?.split('\n').map(pl => (<>{pl}<br/></>))}</div>
        {type === 'PSR' && <div className='mb-1'><b>ПСР-Лидер:</b> {row.psrLeader?.split('\n').map(pl => (<>{pl}<br/></>))}</div>}
        <div><b>Сегмент заказчика:</b> {row.segmentNames}</div>
      </div>
    );
  }

  const projectTypeTitle = {
    PROGRAM: 'данную Программу',
    PORTFOLIO: 'данный Портфель',
    PROJECT: 'данный Проект'
  }

  const columns: any[] = [
    {
      Header: `№ п/п\n(Всего: ${localData.length})`,
      accessor: `index`,
      hideSort: true,
      headerStyle: {width: '40px'},
      style: {textAlign: 'center'},
      Cell: ({value}) => value,
    },
    {
      Header: 'ID',
      accessor: `projectId`,
      Cell: ({value, row}) => (
        <div className='top-report-table-id'>
          {row.original.reportConsolidateStatusCode && (
            <Indicator className={cx("status-report", row.original.reportConsolidateStatusCode)}/>)}
          <div style={{textAlign: 'center'}}>
            {value}
          </div>
        </div>
      ),
    },
    {
      Header: 'Наименование',
      headerStyle: {maxWidth: '200px', minWidth: '200px'},
      style: {maxWidth: '200px', position: 'relative'},
      accessor: `name`,
      sortType: 'string',
      Cell: ({row}) =>
        <div>
          <div className='project-name-text'>
            {(pages[`${row.original.id}_list`] && row.original.id !== projectId) ? (
              <div className='project-name-text-link' onClick={() => setPage(pages[`${row.original.id}_list`])}>
                {nameColGenerator(row.original)}
              </div>
            ) : pages[`${row.original.id}_project`] ? (
              <div className='project-name-text-link' onClick={() => setPage(pages[`${row.original.id}_project`])}>
                {nameColGenerator(row.original)}
              </div>
            ) : (
              <div>
                {nameColGenerator(row.original)}
              </div>
            )}
          </div>

          <div className='project-name-link'>
            <Link to={`/${row.original.typeCode.toLowerCase()}/${row.original.id}`} target='_blank'>
              <LinkIcon/>
            </Link>
          </div>
        </div>,
    },
    {
      Header: 'Отклонения (Сроки/Бюджет/Риски)',
      headerStyle: {maxWidth: '140px', minWidth: '140px'},
      style: {textAlign: 'center'},
      accessor: `sortPoints`,
      Cell: ({row}) => row.original.reportConsolidateStatusCode && (
        <div className='top-report-indicators'>
          <div className='top-report-indicator-container-tooltip'>
          <Indicator className={cx("status-report", row.original.reportTimingStatusCode)}/>
          <Indicator className={cx("status-report", row.original.reportBudgetStatusCode)}/>
          <Indicator className={cx("status-report", row.original.reportRiskStatusCode)}/>
          {row.original.isContainsNonTopChildren
            && <Tooltip
              className={'top-report-tooltip-indicator'}
              width={"20"}
              height={"20"}
              text={`Отчет построен на основании всех входящих 
          в ${projectTypeTitle[row.original.typeCode]} карточек, в т.ч. не имеющих признак "${i18n.t('IN_TOP')}"`}/>}
          </div>
          <br/>
          {`на ${formatDate(row.original.reportDateCreate, FORMAT_DATE)}`}
        </div>
      ),
    },
  ];

  if (['TOP', 'KEY'].includes(type)) {
    columns.push({
      Header: () => (
        <div>
          {'Комментарий к статус-отчету'}
          <Tooltip text='Комментарий Руководителя проекта, заполняемый в статус-отчете' placement='bottom'/>
        </div>
      ),
      style: {verticalAlign: 'top', maxWidth: '390px', wordWrap: 'break-word'},
      accessor: `reportConsolidateComment`,
      Cell: ({value, row}) => <div>
        {row.original.files && (
          <>
            <b>Документы:</b>
            {row.original.files.map(file => {
              const fileData = file.split('%');
              return (
                <div key={fileData[0]} className="rt-card-subtitle">
                  <FileLink fileId={fileData[0]} fileName={fileData[2]} className='button_Link_borderNone'/>
                </div>
              )
            })}
            <br/>
          </>
        )}
        {row.original.reportEscalationComment && (
          <><b>Эскалация:</b>
            <div>{row.original.reportEscalationComment}</div>
            <br/></>
        )}
        {value && (<><b>Текст комментария: </b><TextHide text={value} limit={1000} withModal
                                                         modalTitle='Комментарий к статус-отчету'/></>)}
      </div>,
    });

    columns.push({
      Header: () => (
        <div>
          {'Комментарий корпоративного проектного офиса'}
          <Tooltip text='Комментарий сотрудника департамента корпоративного управления' placement='bottom'/>
        </div>
      ),
      style: {verticalAlign: 'top', maxWidth: '390px', wordWrap: 'break-word'},
      accessor: `reportProjectOfficeComment`,
      Cell: ({value}) => <TextHide text={value} limit={1000} withModal
                                   modalTitle='Комментарий корпоративного проектного офиса'/>,
    });
  }

  if (['PSR'].includes(type)) {
    columns.push({
      Header: () => (
        <div>
          {'Комментарий к статус-отчету'}
          <Tooltip text='Комментарий Руководителя проекта, заполняемый в статус-отчете' placement='bottom'/>
        </div>
      ),
      style: {verticalAlign: 'top', maxWidth: '390px', wordWrap: 'break-word'},
      accessor: `reportConsolidateComment`,
      Cell: ({value, row}) => <div>
        {row.original.files && (
          <>
            <b>Документы:</b>
            {row.original.files.map(file => {
              const fileData = file.split('%');
              return (
                <div key={fileData[0]} className="rt-card-subtitle">
                  <FileLink fileId={fileData[0]} fileName={fileData[2]} className='button_Link_borderNone'/>
                </div>
              )
            })}
            <br/>
          </>
        )}
        {value && (<><b>Текст комментария: </b><TextHide text={value} limit={1000} withModal
                                                         modalTitle='Комментарий к статус-отчету'/></>)}
      </div>,
    });

    columns.push({
      Header: () => (
        <div>
          {'Эскалация'}
          <Tooltip text='Комментарий сотрудника, ответственного за реализацию проекта' placement='bottom'/>
        </div>
      ),
      style: {verticalAlign: 'top', maxWidth: '390px', wordWrap: 'break-word'},
      accessor: `reportEscalationComment`,
      Cell: ({value, row}) => <div>
        {value && (<div>{value}</div>)}
      </div>,
    });
  }

  const title = () => {
    if (projectId) {
      const firstProject = data[0];
      return `${getProjectTypeTextNominative(firstProject.typeCode)} «${firstProject.name}»`;
    } else if (['TOP', 'KEY'].includes(type)) {
      return `Статус-отчет по Портфелю ${type === 'TOP' ? `«${i18n.t('IN_TOP')}»` : 'Ключевых проектов'}`;
    } else {
      return 'Статус-отчет Офиса трансформации';
    }
  }

  const exportXlsx = () => {
    const nameMap = {TOP: 'top100Report', KEY: 'KeyReport', 'PSR': 'psrReport'};

    topReportExcelExport(
      localData, {
        type: type,
        name: nameMap[type],
        context: {
          dict
        }
      })
  };

  return (
    <div className="presentation__page__center top-report">
      <Title className='frame-list-title'
             pageTitle={title()}>
        {title()}
        <button className="link dashboard__card-title__reset" onClick={prevent(exportXlsx)}>
          Выгрузка в Excel
        </button>
      </Title>

      {isEmpty(data) ? (
        'Данные не найдены'
      ) : (
        <>
          {['TOP', 'PSR'].includes(type) && (
            <div className='top-report-filters row'>
              <div className={type === 'TOP' ? 'col-4' : 'col-24'}>
                <Select options={segmentForSelect}
                        label="Сегмент заказчика"
                        value={filter.segment}
                        isClearable
                        isMulti
                        onChange={val => setFilterByKey('segment', val)}/>
              </div>

              <div className={type === 'TOP' ? 'col-4' : 'col-24'}>
                <Select options={reportStatusForSelect}
                        label="Сводный статус"
                        value={filter.reportStatus}
                        isClearable
                        isMulti
                        onChange={val => setFilterByKey('reportStatus', val)}/>
              </div>

              <div className={type === 'TOP' ? 'col-4' : 'col-24'}>
                <Input label='Наименование карточки'
                       value={filter.projectName}
                       onChange={val => setFilterByKey('projectName', val.target.value)}
                       isClearable={true}/>
              </div>

              {['PSR'].includes(type) && (
                <Fragment>
                  <div className='col-24'>
                    <Select options={reportProjectStatusForSelect}
                            label="Статус"
                            value={filter.projectStatus}
                            isClearable
                            isMulti
                            onChange={val => setFilterByKey('projectStatus', val)}/>
                  </div>

                  <div className='col-24'>
                    <Select options={reportDirectionForSelect}
                            label="Направление проекта"
                            value={filter.direction}
                            isClearable
                            isMulti
                            onChange={val => setFilterByKey('direction', val)}/>
                  </div>
                </Fragment>
              )}
            </div>
          )}

          <StatusConsolidate data={localData}/>

          <div className='top-report-table-wrapper'>
            <TableBeta columns={columns}
                       data={localData}
                       className={cx('table-responsive custom-scrollbar custom-width-scrollbar', ['TOP', 'PSR'].includes(type) ? 'TOP' : 'KEY')}
                       showSort={true}/>
          </div>
        </>
      )}
    </div>
  )
}

export default FrameList;