import React from 'react';
import { connect } from 'react-redux';
import PageTitle from '@/components/PageTitle'
import ButtonRt from '@/components/Helper/ButtonRt';
import service from '@/services';
import qs from 'query-string';
import withDeviceQuery from '@/hocs/withDeviceQuery';
import { Modal } from 'react-bootstrap';
import { compact, isEmpty, range } from 'lodash';
import Table from '@/components/Table';
import TeamLoadFilter from './Filter';
import moment from 'moment';
import { addAllSet, momentToSelectDate } from '@/utils';
import i18n from 'i18next';

const getTableXls = (data) => service.postDownload('/consolidated-report/employees-load/xls', data)
const procentFormatter = (cell) => (cell || 0) + "%";

export const monthHead = [
  {
    dataField: 'jan',
    text: 'Янв'
  },
  {
    dataField: 'feb',
    text: 'Фев'
  },
  {
    dataField: 'mar',
    text: 'Мар'
  },
  {
    dataField: 'apr',
    text: 'Апр'
  },
  {
    dataField: 'may',
    text: 'Май'
  },
  {
    dataField: 'jun',
    text: 'Июн'
  },
  {
    dataField: 'jul',
    text: 'Июл'
  },
  {
    dataField: 'aug',
    text: 'Авг'
  },
  {
    dataField: 'sep',
    text: 'Сен'
  },
  {
    dataField: 'oct',
    text: 'Окт'
  },
  {
    dataField: 'nov',
    text: 'Ноя'
  },
  {
    dataField: 'dec',
    text: 'Дек'
  },
];

export const reportType = [
  { value: 1, label: "отчет по сотруднику" },
  { value: 2, label: "отчет по ролям" }
];

const toNativeDate = (date) => new Date(date.year, date.month - 1, date.day)

export const isBetweenDate = (from, to, day) => {
  if (!day || !from || !to) return false;
  const nativeDay = toNativeDate(day);
  const nativeFrom = toNativeDate(from);
  const nativeTo = toNativeDate(to);
  return nativeDay >= nativeFrom && nativeDay <= nativeTo;
}

const getNeedHighlightField = (from, to, day) => {
  return isBetweenDate(from, to, day)
}

const getColumns = ({
  reportType,
  isNeedHighlight,
  monthYearSelect
}) => compact([
  {
    dataField: 'recordName',
    text: reportType === 1 ? 'ФИО Сотрудника' : 'Роль',
    sort: true,
    headerStyle: () => {
      return { minWidth: '230px' };
    },
  },
  reportType === 1 ? {
    dataField: 'post',
    text: 'Должность',
    sort: true,
  } : undefined,
  {
    dataField: 'year',
    text: 'Год',
    sort: true,
  },
  ...monthHead.map(({ dataField, text }, index) => ({
    dataField,
    text,
    sort: true,
    formatter: procentFormatter,
    classes: (_, row) => {
      const isNeedHighlightField = isNeedHighlight && getNeedHighlightField(
        monthYearSelect.from,
        monthYearSelect.to,
        {
          day: 1,
          year: row.year,
          month: index + 1
        });

      return isNeedHighlightField ? 'report-select' : ''
    }
  })),
]);

const detailColumns = [
  {
    dataField: 'userName',
    text: 'ФИО',
    sort: true
  },
  {
    dataField: 'email',
    text: 'Email',
    sort: true
  },
  {
    dataField: 'company',
    text: 'Организация',
    sort: true
  },
  {
    dataField: 'department',
    text: 'Подразделение',
    sort: true
  },
  {
    dataField: 'post',
    text: 'Должность',
    sort: true
  },
  {
    dataField: 'projectType',
    text: 'Тип карточки',
    sort: true
  },
  {
    dataField: 'projectId',
    text: 'ID',
    sort: true
  },
  {
    dataField: 'projectName',
    text: 'Название',
    sort: true,
    style: {
      cursor: "pointer",
      textDecoration: "underline"
    },
    events: {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        var win = window.open("/project/" + row.projectVersionId + "/team/load", '_blank');
        win.focus();
      }
    }
  },
  {
    dataField: 'isClosed',
    text: 'Статус карточки',
    sort: true,
    formatter: (cell, row) => cell ? 'Закрыто' : row.statusName,
  },
  {
    dataField: 'projectLeaders',
    text: 'ФИО Руководителя',
    sort: true,
    formatter: (cell: string, row) => cell.split(';').map((item) => {
      const leader = item.split(',');
      return (
        <div>
          <a
            target="_blank"
            href={'https://w3c.portal.rt.ru/profiles/html/searchProfiles.do?'
              + `lang=ru#simpleSearch&q=${leader[1]}`}
            rel="noreferrer"
          >
            {leader[0]}
          </a>
        </div>
      )
    })
  },
  {
    dataField: 'role',
    text: 'Роль',
    sort: true
  },
  {
    dataField: 'year',
    text: 'Год',
    sort: true
  },
  ...monthHead.map(({ dataField, text }) => ({
    dataField,
    text,
    sort: true,
    formatter: procentFormatter
  })),
  {
    dataField: 'isInTop',
    text: i18n.t('IN_TOP'),
    sort: true,
    formatter: (cell: string, row) => cell ? 'Да' : ''
  },
  {
    dataField: 'isFired',
    text: 'Сотрудник уволен',
    sort: true,
    formatter: (cell: string, row) => cell ? 'Да' : ''
  },
  {
    dataField: 'isHidden',
    text: 'Сотрудник скрыт',
    sort: true,
    formatter: (cell: string, row) => cell ? 'Да' : ''
  },
];

const getDefaultFilter = () => ({
  reportType: 1,
  year: null,
  monthYear: {
    from: momentToSelectDate(moment().startOf('year')),
    to: momentToSelectDate(moment().startOf('year').add(11, 'month')),
  },
  project: null,
  userLogin: null,
  role: null,
  isHideClosed: true,
  isOnlyRelease: true,
  isShowNoLoad: false,
  isCoordinationPriority: false,
  searchFiredModeId: null
});

const getDataField = (item) => item.dataField;

const getMonthRange = (from, to) => {
  const result = new Set();

  if (!from || !to) {
    return result;
  } else if (from.month === 1 && to.month === 12) {
    // когда выбран весь год, то тоже подсвечивать не будем
    return result;
  } else if (from.year === to.year) {
    addAllSet(result, ...monthHead.slice(from.month - 1, to.month).map(getDataField));
  } else {
    addAllSet(
      result,
      ...monthHead.slice(from.month - 1).map(getDataField),
      ...monthHead.slice(0, to.month).map(getDataField));
  }

  return result;
}

const isNeedHighlight = (from, to) => getMonthRange(from, to).size !== 0;

const getYear = (filter) => {
  if (filter.year?.length) {
    return filter.year;
  }

  const { from, to } = filter.monthYear || {}

  if (!from || !to) {
    return [];
  }

  return range(from.year, to.year + 1);
}

class TeamLoad extends React.Component<any, any> {
  state = {
    data: getDefaultFilter(),
    result: null,
    detail: null,
    reportTypeLocal: 1,
    monthYearLocal: {
      from: null,
      to: null,
    },
    modalShow: false
  };

  componentDidUpdate(_, prevState) {
    if (this.state.data.isOnlyRelease && !prevState.data.isOnlyRelease && this.state.data.isCoordinationPriority) {
      this.setState({
        data: {
          ...this.state.data,
          isCoordinationPriority: false,
        }
      });
    }
  }

  handleChange = (e) => {
    this.handleChangeSelect(e.target.name, e.target.value)
  };

  setData = (data) => {
    this.setState((prevData) => ({
      data: {
        ...prevData.data,
        ...data
      }
    }));
  }

  handleChangeSelect = (key, value) => {
    this.setData({
      [key]: value,
    });
  };

  clearFilter = async () => {
    this.setState({
      data: getDefaultFilter(),
      result: null
    });
  };


  getReportParam = () => {
    return {
      projectName: this.state.data.project == null ? null : this.state.data.project.label,
      projectId: this.state.data.project == null ? null : this.state.data.project.projectId,
      year: getYear(this.state.data),
      reportType: this.state.data.reportType,
      role: this.state.data.role,
      userLogin: this.state.data.userLogin == null ? null : this.state.data.userLogin.value,
      isHideClosed: this.state.data.isHideClosed,
      isOnlyRelease: this.state.data.isOnlyRelease,
      isShowNoLoad: this.state.data.isShowNoLoad,
      isCoordinationPriority: this.state.data.isCoordinationPriority,
      searchFiredModeId: this.state.data.searchFiredModeId
    };
  }

  buildReport = async () => {
    this.setState({
      ...this.state,
      result: null
    });

    return service.post(`/consolidated-report/employees-load`, this.getReportParam())
      .then(data => {
        this.setState({
          ...this.state,
          reportTypeLocal: this.state.data.reportType,
          monthYearLocal: this.state.data.monthYear,
          result: data.map(e => {
            let res = {
              ...e,
              rowId: e.id + "|" + e.year
            };
            return res;
          })
        });

      });
  };

  rowEvents = {
    onClick: (_, row) => {
      this.setState({ ...this.state, detail: null });
      return service.post(`/consolidated-report/employees-load/detail`, {
        ...this.getReportParam(),
        recordId: row.recordId,
        year: [row.year || -1],
      })
        .then(data => {
          this.setState({ ...this.state, detail: data, modalShow: true });
        });
    }
  };


  hide = () => {
    this.setState({
      ...this.state,
      modalShow: false,
      detail: null
    })
  }

  setDefaultParams() {
    const params = qs.parse(window.location.search);

    if (isEmpty(params)) {
      return;
    }

    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        year: [+params.year] || prevState.data.year,
        role: +params.roleId || prevState.data.roleId,
        userLogin: params.userLogin ? {
          value: params.userLogin,
          label: params.userDisplayName
        } : undefined
      }
    }));

    setTimeout(this.buildReport)
  }

  downloadXls = () => {
    return getTableXls(this.getReportParam())
  }

  componentDidMount() {
    this.setDefaultParams();
  }

  render() {
    const { isDesktop } = this.props;
    const columns = getColumns({
      reportType: this.state.reportTypeLocal,
      isNeedHighlight: isNeedHighlight(this.state.monthYearLocal.from, this.state.monthYearLocal.to),
      monthYearSelect: this.state.monthYearLocal,
    });

    return (
      <div className="report-wrapper">
        {isDesktop && <PageTitle>Сводный отчет по загрузке сотрудников</PageTitle>}
        <div className="consolidate-report-filter-felix">
          <div className="consolidate-report-filter-block-felix">
            {!isDesktop && (
              <div className="consolidate-report-filter_title">
                <div className="consolidate-report-filter_text">Фильтр</div>
                <div className="consolidate-report-filter_clear" onClick={this.clearFilter}>Очистить</div>
              </div>
            )}
            <TeamLoadFilter
              data={this.state.data}
              setData={this.setData}
              handleChangeSelect={this.handleChangeSelect}
            />

          </div>

          <div className="report-lesson__buttons button-group">
            <ButtonRt onClick={this.buildReport}>Сформировать</ButtonRt>
            <ButtonRt type={!isDesktop && 'outline'} onClick={this.downloadXls}>Выгрузить в Excel</ButtonRt>
            {isDesktop &&
              <ButtonRt type="outline animation-disable margin-left-multiple" onClick={this.clearFilter}>Очистить фильтр</ButtonRt>
            }
          </div>
        </div>

        <div className="dashboard-table__container">
          {this.state.result !== null ? (
            <div className="consolidate-report-table-wrapper">
              <Table
                hover={true}
                keyField='recordId'
                data={this.state.result}
                columns={columns}
                rowEvents={this.rowEvents}
                wrapperClasses="pointer-row"
                showCount
              />
            </div>
          ) : null}
        </div>
        <Modal
          onHide={this.hide}
          show={this.state.modalShow}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          size="xl"
          centered={true}
          className="consolidate-report-team-load"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <div className="h3">Детальный отчёт по загрузке сотрудников</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.detail !== null && (
              <div className="overflow-auto">
                <Table
                  keyField='id'
                  data={this.state.detail}
                  columns={detailColumns}
                  wrapperClasses="no-wrap-header"
                />
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }

}
const mapStateToProp = (state) => ({
  dict: state.dict,
});

export default connect(mapStateToProp, null)(withDeviceQuery(TeamLoad));
