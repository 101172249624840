import { get, isFunction } from 'lodash';

// объединение с сохранением порядка
const unionSafeSort = (...arrs) => {
    const out = [];
    for (let i = 0, l = arrs.length; i < l; i++) {
        for (let j = 0, jl = arrs[i].length; j < jl; j++) {
            const currEl = arrs[i][j];
            if (out.indexOf(currEl) === -1) {
                if (j - 1 !== -1 && out.indexOf(arrs[i][j - 1]) > -1) {
                    out.splice(out.indexOf(arrs[i][j - 1]) + 1, 0, currEl);
                } else if (out.indexOf(arrs[i][j + 1]) > -1) {
                    out.splice(out.indexOf(arrs[i][j + 1]), 0, currEl);
                } else {
                    out.push(currEl);
                }
            }
        }
    }
    return out;
};

export const getGroupData = (d1 = [], d2 = [], key = 'title') => {
  const allKey = unionSafeSort(d1.map(v => v[key]), d2.map(v => v[key]));
  const allTitle = {};
  d1.forEach((item) => {
    allTitle[item[key]] = isFunction(item.title) ? item.title(item, allKey.indexOf(item.key)) : item.title
  })
  d2.forEach((item) => {
    allTitle[item[key]] = isFunction(item.title) ? item.title(item, allKey.indexOf(item.key)) : item.title
  })

  const d1Hash = d1.reduce((acc, item) => ({
    ...acc,
    [item[key]]: item
  }), {});

  const d2Hash = d2.reduce((acc, item) => ({
    ...acc,
    [item[key]]: item
  }), {});

  return allKey.map(key => ({
    title: allTitle[key],
    data: [
      get(d1Hash[key], 'data'),
      get(d2Hash[key], 'data'),
    ],
    _raw: {
      item1: d1Hash[key],
      item2: d2Hash[key]
    }
  }));
}

export const prepareDataForTable = (data, dataSecond, isDiff, isOnlyUpdate, key = undefined, isSubDataHasDiff = false) => {
  let groupedData = getGroupData(data, dataSecond, key);

  if (!isSubDataHasDiff && isDiff && isOnlyUpdate) {
    groupedData = groupedData.filter(v => v.data[0] !== v.data[1]);
  }

  return groupedData;
}
