import SelectInTable from "@/elements/Select/SelectInTable";
import { getRound } from "@/utils";
import React from "react";
import { getYearsFilter, isSkipFiltered } from '@/pages/CreateProject/Budget/util'
import moment from "moment";
import cx from 'classnames'
import { BudgetContractedTableProps } from "@/pages/CreateProject/Blocks/BudgetPrediction/helper";

const BudgetContractedTable = ({
  data,
  yearFilter,
  filterChange,
  getSumCol,
  tableHash,
}: BudgetContractedTableProps) => {
  const yearsFilter = getYearsFilter(data.contractedList);

  return (
    <div className="flex-space-between">
      <div className={cx("prediction-table__wrapper")}>
        <table className="table table-hove table-felix budget-table__container contracted-budget-table">
          <thead className="budget-table__head">
            <tr>
              <td className="year-col">Год</td>
              <td className="left-border">Месяц</td>
              <td rowSpan={2} className="left-border spanned-bottom">ОКВ</td>
              <td rowSpan={2} className="left-border spanned-bottom">ДДС</td>
            </tr>
            <tr>
              <td className="table-mini-select">
                <SelectInTable
                  options={yearsFilter}
                  label="Год"
                  placeholder="Год"
                  value={yearFilter}
                  onChange={filterChange}
                  hasError={false}
                  multiple={false}
                />
              </td>
              <td className="left-border"></td>
            </tr>
          </thead>
          <tbody>
            {data.contractedList.map((listItem, i) => {
              if (isSkipFiltered(listItem, yearFilter)) {
                return null;
              }

              const effectiveDate = moment(listItem.effectiveDate);

              return (
                <tr key={listItem.effectiveDate + tableHash + i}>
                  <td>
                    {effectiveDate.format("YYYY")}
                  </td>
                  <td className="left-border">
                    {effectiveDate.format("MMMM")}
                  </td>
                  <td className="left-border">
                    {getRound(listItem.okb)}
                  </td>
                  <td className="left-border">
                    {getRound(listItem.dds)}
                  </td>
                </tr>
              )
            }
            )}
            <tr>
              <td>Итого</td>
              <td></td>
              <td>{getRound(getSumCol('okb'))}</td>
              <td>{getRound(getSumCol('dds'))}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default BudgetContractedTable;

