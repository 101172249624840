const AgreementKpiYearsWeightBlock = (self) => ([
  {
    multiple: true,
    generator: (data) => {
      if (!data.kpiYearsWeight) {
        return []
      }

      return data.kpiYearsWeight.map(item => ({
        title: `${item.year}`,
        data: item.weight,
      }));
    }
  },
])

export default AgreementKpiYearsWeightBlock;
