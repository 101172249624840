import moment from 'moment';
import { formatDate, selectDateToMoment } from '@/utils';
import { FORMAT_DATE } from '@/config/const'

export const getStatusText = (work) => {
  if (!work.status) {
    return;
  }

  const workStatusCode = work.status.code;
  const workStatusName = work.status.name;

  if (['SUCCESS', 'MILESTONE_SUCCESS', 'MILESTONE_SUCCESS_WITH_COMMENT'].includes(workStatusCode)) {
    return `${workStatusName} ${formatDate(work.dateEndFact, FORMAT_DATE)}`
  }

  if (workStatusCode === 'CANCEL') {
    return `${workStatusName} ${formatDate(work.dateUpdate, FORMAT_DATE)}`
  }

  if (workStatusCode === 'WORK') {
    return `${workStatusName} до ${formatDate(work.dateEnd, FORMAT_DATE)}`
  }

  if (workStatusCode === 'PLAN') {
    return `${workStatusName} с ${formatDate(work.dateStart, FORMAT_DATE)}`
  }

  return workStatusName;
}

export const getWorkSuccessInfo = (row) => {
  if (row.skup) {
    return {
      cnt: row.skup.cnt,
      cntSuccess: row.skup.cntSuccess
    }
  }

  return {
    cnt: 1,
    cntSuccess: ['SUCCESS', 'MILESTONE_SUCCESS', 'MILESTONE_SUCCESS_WITH_COMMENT', 'PLAN'].includes(row.status.code) ? 1 : 0
  }
}

export const getDelayOfDay = (work) => {
  const workStatusCode = work.status.code;

  if (workStatusCode === 'PLAN') {
    const dateStart = selectDateToMoment(work.dateStart);
    const dateDiff = moment().diff(dateStart, 'days')

    return dateDiff > 0 ? dateDiff : null;
  }

  if (workStatusCode === 'WORK') {
    const dateEnd = selectDateToMoment(work.dateEnd);
    const dateDiff = moment().diff(dateEnd, 'days')

    return dateDiff > 0 ? dateDiff : null;
  }

  return null;
}

export const getWorkDelayColorIndicator = (work) => {
  const delayOfDay = getDelayOfDay(work);

  if (delayOfDay === null) {
    return 'GREEN';
  }

  const procent = (delayOfDay / work.duration) * 100;

  const getColor = (value) => {
    if (value > 30) {
      return 'RED'
    } else if (value > 0) {
      return 'YELLOW'
    }

    return 'GREEN'
  }

  return getColor(procent);
}
