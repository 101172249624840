import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom"
import { inRange, isEmpty } from 'lodash';
import { useDeviceContext } from "@/context/DeviceContext";
import cx from 'classnames';
import { ProjectDocument } from "@/pages/CreateProject/BasicNav/Icon";
import NavigationButton from "@/pages/CreateProject/CloseReport/elements/NavigationButton";
import { useCurrentPage, useFontSize } from "@/pages/CreateProject/CloseReport/helper";
import { useIsKeyProjectScreen } from "@/pages/Projects/ProjectItem";
import { randomNumber } from '@/pages/Dashboard2/utils';
import { useEscape } from "@/utils/hooks";

const PresentationPage = ({ isLoad, frames, pages, type, title }) => {
  const [currentFrameNumber, setCurrentFrameNumber] = useCurrentPage({ maxLength: frames.length });
  const [fontSize, setFontSize] = useFontSize(14);
  const [pageStack, setPageStack] = useState([]);
  const [uniqReportKey, setUniqReportKey] = useState(null);
  const url = useLocation();
  const isKeyProjectScreen = useIsKeyProjectScreen();
  const closeUrl = isKeyProjectScreen ? '/all' : '/';
  const currentFrame = frames[currentFrameNumber];
  const Frame = currentFrame?.component;
  const { isMobile, isTablet } = useDeviceContext();
  const isMini = isMobile || isTablet;

  const navigate = useNavigate();
  useEscape(() => navigate(closeUrl));

  useEffect(() => {
    setUniqReportKey(randomNumber());
  }, [])

  const nextPage = () => {
    const next = currentFrameNumber + 1;
    setPage(next);
  };

  const prevPage = () => {
    const prev = currentFrameNumber - 1;
    setPage(prev);
  };

  const startPage = () => {
    setPage(0);
    setUniqReportKey(randomNumber());
  };

  const backPage = () => {
    const back = pageStack.pop();
    setCurrentFrameNumber(() => back);
    setPageStack(pageStack);
  };

  const setPage = (pageNum) => {
    setCurrentFrameNumber(() => pageNum);
    pageStack.push(currentFrameNumber)
    setPageStack(pageStack);
  }

  return (
    <div className={cx("presentation__container", { 'mobile': isMini })} style={isMini ? {} : { fontSize: `${fontSize}px` }}>
      <div className="presentation__head__container">
        <Link to="/" className="brand-name presentation__logo">
          <div className="logo" />
        </Link>

        <Link to={`${url.pathname}/pdf`} target="_blank" className="presentation__download">
          <ProjectDocument />
        </Link>

        {!isMini && (
          <>
            <div onClick={() => setFontSize(fontSize - 1)} className="presentation__font-dec">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M2.50393 14H0.696289L6.1998 0H7.7998L13.3041 14H11.498L9.97424 10H4.02902L2.50393 14ZM7.00293 2.2L9.40283 8.5H4.60092L7.00293 2.2ZM20 5.75H13V7.25H20V5.75Z" fill="#101828" />
              </svg>
            </div>

            <div onClick={() => setFontSize(fontSize + 1)} className="presentation__font-inc">
              <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.696289 14H2.50393L4.02902 10H9.97424L11.498 14H13.3041L7.7998 0H6.1998L0.696289 14ZM9.40283 8.5L7.00293 2.2L4.60092 8.5H9.40283ZM17.25 5.75H20V7.25H17.25V10H15.75V7.25H13V5.75H15.75V3H17.25V5.75Z" fill="#101828" />
              </svg>
            </div>      
          </>
        ) }
    

        <Link to={closeUrl} className="presentation__close">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.93912 8.00052L0.998047 13.9416L2.0587 15.0023L7.99978 9.06119L13.9409 15.0023L15.0015 13.9416L9.06044 8.00052L15.0015 2.05944L13.9408 0.998779L7.99978 6.93986L2.05872 0.998779L0.998066 2.05944L6.93912 8.00052Z" fill="#101828" />
          </svg>
        </Link>
      </div>
      <div className="presentation__body__container custom-scrollbar custom-width-scrollbar">
        <div className="presentation__body__item presentation__wrapper">
          {!isLoad ? (
            <div className="presentation__page__center text-muted">
              Загрузка...
            </div>
          ) : (!!currentFrame ? (
              <Frame key={currentFrame.parentId}
                     pageNumber={currentFrameNumber}
                     uniqReportKey={uniqReportKey}
                     projectId={currentFrame.parentId}
                     data={currentFrame.data}
                     type={type} isPdf={false}
                     pages={pages}
                     setPage={setPage} />
            ) : (isEmpty(frames) ? (
              <div className="presentation__page__center text-muted">
                Нет проектов с Вашим участием
              </div>
            ) : (
              <div className="presentation__page__center text-muted">
                Слайд не найден
              </div>
            )
          ))}
        </div>
      </div>
      <div className="presentation__footer__container">
        <div className="top-report-footer-container">
          <div className="mb-2">
            <button className={cx("presentation__nav_button", 'top-report-nav-button', type)} disabled={!currentFrameNumber} onClick={startPage}>
              В начало
            </button>
            <button className={cx("presentation__nav_button", 'top-report-nav-button', type)} disabled={!pageStack.length} onClick={backPage}>
              Назад
            </button>
          </div>
          <div className="mb-2">
            <NavigationButton
              type="left"
              onClick={prevPage}
              disabled={!inRange(currentFrameNumber - 1, frames.length)}
            />

            <span className="presentation__nav__count">
              {`Слайд ${currentFrameNumber + 1} из ${frames.length}`}
            </span>

            <NavigationButton
              type="right"
              onClick={nextPage}
              disabled={!inRange(currentFrameNumber + 1, frames.length)}
            />
          </div>
          {isLoad && (
            <div className="mb-2">
              <span className="presentation__project-type__container">
                {title}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PresentationPage;