import cx from 'classnames';
import React from 'react';

import InputTitle from '@/components/InputTitle';
import { isEmptyValues } from '@/utils';

import SelectBase from './SelectBase';
import SelectCreateble from './SelectCreateble';

export const customStyles = {
  input: provided => ({
    ...provided,
    margin: '0 2px',
    padding: '0',
  }),
  valueContainer: (provided, state) => ({ ...provided, color: state.isDisabled ? 'rgb(153, 153, 153)' : 'unset' }),
  menu: provided => ({ ...provided, zIndex: 9999 }),
  menuPortal: provided => ({
    ...provided,
    zIndex: 9999,
    padding: 0,
    margin: 0,
    marginTop: '2px',
    marginLeft: '-16px',
    width: provided.width + 32,
  }),
};

export default class Select extends React.Component<SelectProps, any> {

  getDescription(value) {
    if (this.props.showDescription && value) {
      return value.desc;
    } else {
      return this.props.description;
    }
  }

  render() {
    if (this.props.isHide) {
      return null;
    }

    const getLabelWithDescription = (option) => {
      if (!option.description) {
        option.description = option.desc;
      }
      if (!this.props.descriptionInOptions || !option.description) {
        return option.label;
      }
      return <div>
        <span>{option.label}</span>
        <div className='select-option__description'>{option.description}</div>
      </div>
    }

    const options = this.props.options.map(item => ({
      isDisabled: this.props.maxSelect
        && this.props.value
        && this.props.value.length >= this.props.maxSelect,
      ...item,
      initLabel: item.label?.toString(),
      label: getLabelWithDescription(item),
    }));

    const value = isEmptyValues(this.props.value) ? null : this.props.value;
    const description = this.getDescription(value);

    const selectArgs = {
      styles: customStyles,
      ...this.props,
      value: value,
      options: options,
      className: cx("select-rt", this.props.className),
      classNamePrefix: cx("select-rt"),
      blurInputOnSelect: false,
      openMenuOnFocus: true,
      menuPortalTarget: this.props.isPortal ? document.querySelector("#select-portal") : null,
      filterOption: (option, input) => !input || (this.props.createble && option.data.__isNew__)
          || (!this.props.createble && option.data.initLabel.toLowerCase().includes(input.toLowerCase())),
    };

    const Component = this.props.createble ? SelectCreateble : SelectBase;

    return (
      <InputTitle
        required={this.props.required}
        tooltip={this.props.tooltip}
        isClearable={(this.props.isClearable || this.props.isMulti) && !this.props.disabled}
        label={this.props.label}
        value={value}
        className={cx(this.props.titleClassName, 'input-select')}
        description={description}
        disabled={this.props.disabled}
        hideRequiredDesc={this.props.hideRequiredDesc}
        descriptionNewLine={this.props.descriptionNewLine}
      >
        <Component {...selectArgs} />
      </InputTitle>
    );
  }
}
