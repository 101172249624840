import { Card } from 'react-bootstrap';
import React from 'react';
import Tooltip from '@/elements/Tooltip';
import cx from 'classnames'

export const DashboardCardHeader = ({ title, titleMiddle, desc, titleRight, className }) => {
  if (!title) {
    return null;
  }

  return (
    <Card.Title className={cx("dashboard__card-title__container", className)}>
      {title}
      {titleMiddle}
      <Tooltip text={desc} placement="top" />
      {titleRight}
    </Card.Title>
  )
}

const DashboardCard = ({
  title = undefined,
  desc = undefined,
  titleMiddle = undefined,
  titleRight = undefined,
  children = undefined,
  withLine = undefined,
  titleClassName = undefined,
  className = undefined,
  mini = undefined,
  bodyClassName = undefined
}) => {
  return (
    <Card className={cx("dashboard-new card noSpoiler", className, { withLine, mini })}>
      <DashboardCardHeader title={title} titleMiddle={titleMiddle} desc={desc} titleRight={titleRight} className={titleClassName} />
      <Card.Body>
        <div className={cx('card', bodyClassName)}>
          {children}
        </div>
      </Card.Body>
    </Card>
  )
}

export default DashboardCard;
