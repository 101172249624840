import './style.scss';
import React, { useEffect, useState } from "react";
import '@/pages/CreateProject/CloseReport/index.scss'
import service from "@/services";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/utils/typedHooks";
import { loadAllDict } from "@/actions/dict";
import PresentationPage from "@/pages/TopReport/pages/PresentationPage";
import PdfPage from "@/pages/TopReport/pages/PdfPage";
import FrameList from "@/pages/TopReport/frames/FrameList";
import { sortBy } from "lodash";
import FrameProject from "@/pages/TopReport/frames/FrameProject";
import { dictTreeToDict, getDictObjList } from "@/utils";
import { useCheckRight } from "@/utils/hooks";
import { isUserActive } from '@/utils/user';
import { loadUserInfo } from "@/actions/user";
import { UserRight } from '@/config/const';
import i18n from 'i18next';

type TopReportType = 'TOP' | 'KEY' | 'PSR';

const loadData = (filter) => service.post('/topReport', filter);

const convertCpi = (value) => value ? (2 * value) / (value + 1) : 0;

const projectSortPoints = (typeCode) => {
  switch (typeCode) {
    case 'PROJECT': return 3;
    case 'PROGRAM': return 2;
    case 'PORTFOLIO': return 1;
    default: return 0;
  }
};

const TopReport = ({
  type,
}: {
  type: TopReportType,
}) => {
  const history = useNavigate();
  const checkRight = useCheckRight();

  const segmentDict = useAppSelector(state => state.dict.segment);
  const userStatusDict = useAppSelector(state => state.dict.userStatus);
  const currentUserRights = useAppSelector(state => state.Login.currentUser.rightCodes);
  const [frames, setFrames] = useState([]);
  const [pages, setPages] = useState<any>({});
  const [isLoad, setLoad] = useState(false);

  const isUserActiveCheck = (user) => isUserActive(userStatusDict, user);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadAllDict);
  }, []);

  useEffect(() => {
    if (!currentUserRights && userStatusDict.isLoad) {
      dispatch(loadUserInfo(isUserActiveCheck));
    }
  }, [userStatusDict.isLoad]);

  useEffect(() => {
    if (currentUserRights
      && (type === 'TOP' && !checkRight(UserRight.SYSTEM_SECTION_TOP_REPORT_ACCESS)
        || type === 'KEY' && !checkRight(UserRight.SYSTEM_SECTION_KEY_REPORT_ACCESS)
        || type === 'PSR' && !checkRight(UserRight.SYSTEM_SECTION_PSR_REPORT_ACCESS))) {
      history('/');
    }
  }, [currentUserRights]);

  useEffect(() => {
    if (!segmentDict.isLoad || isLoad) {
      return;
    }

    loadData({
      isInTop: type === 'TOP',
      isKeyProject: type === 'KEY',
      isPsr: type === 'PSR'
    })
      .then(rows => rows.map(row => {
        console.log(row)
        return {
          ...row,
          segmentNames: getDictObjList(dictTreeToDict(segmentDict), row.segmentIds)?.map(d => d.name).join(', ') || '',
          cpiOkbMonth: convertCpi(row.cpiOkbMonth),
          cpiDdsMonth: convertCpi(row.cpiDdsMonth),
        }
      }))
      .then(prepareData)
      .then(() => setLoad(true));


  }, [segmentDict.isLoad, isLoad]);

  const prepareData = (data) => {
    const rowsByParents = new Map();
    data.forEach(item => {
      let parentId = 0;
      if (item.typeCode === 'PROJECT') {
        parentId = item.parentProgramId || item.parentPortfolioId || 0;
      } else if (item.typeCode === 'PROGRAM') {
        parentId = item.parentPortfolioId || 0;
      }
      if (parentId && !data.some(p => p.id === parentId)) {
        parentId = 0;
      }

      const rowsParent = rowsByParents.get(parentId) || [];
      rowsParent.push(item);
      rowsByParents.set(parentId, rowsParent);

      const rowsSelf = rowsByParents.get(item.id) || [];
      rowsSelf.unshift(item);
      rowsByParents.set(item.id, rowsSelf);
    });

    let frameList = [];
    rowsByParents.forEach((val, key) => {
      const parent = data.find(p => p.id === key);

      if (!parent || parent.typeCode !== 'PROJECT') {
        frameList.push({
          type: 'list',
          component: FrameList,
          data: val,
          parentId: key,
          sortPoints: projectSortPoints(parent?.typeCode),
        });
      }

      if (parent?.statusReportId) {
        frameList.push({
          type: 'project',
          component: FrameProject,
          data: val.slice(0, 1),
          parentId: key,
          sortPoints: projectSortPoints(parent.typeCode) + 10,
        });
      }
    });

    frameList = sortBy(frameList, 'sortPoints');

    const framePage = {};
    frameList.forEach((frame, i) => {
      framePage[`${frame.parentId}_${frame.type}`] = i;

      frame.data = frame.data.sort((r1, r2) => r2.sortPoints - r1.sortPoints);
    });

    setFrames(frameList);
    setPages(framePage);
  };

  const getTitle = () => {
    const template = 'Статус-отчет по Портфелю';
    if (type === 'TOP') {
      return `${template} «${i18n.t('IN_TOP')}»`;
    } else if (type === 'KEY') {
      return `${template} Ключевых проектов`;
    } else if (type === 'PSR') {
      return 'Статус-отчет Офиса трансформации';
    }
  }

  return (
    <Routes>
      <Route path={`/pdf`} element={
        <PdfPage isLoad={isLoad}
                 frames={frames}
                 pages={pages}
                 type={type}
                 title={getTitle()} />
      } />

      <Route path="*" element={
        <PresentationPage isLoad={isLoad}
                          frames={frames}
                          pages={pages}
                          type={type}
                          title={getTitle()} />
      } />
    </Routes>

  )
}

export default TopReport;