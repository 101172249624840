import i18n from "i18next";
import { get } from 'lodash';

import { dictTreeToDict, formatDate, getDict, getDictCodeById } from '@/utils';

const AgreementGeneralBlock = (self) => ([
  {
    multiple: true,
    generator: (data) => {
      const result = [];
      const projectTypeCode = data.projectTypeCode;
      const businessProcessCode = getDictCodeById(self.props.dict.businessProcess, data.businessProcessId);

      result.push(
        {
          title: i18n.t('Input name'),
          data: data.name
        },
      );

      if (businessProcessCode === 'B2O') {
        result.push(
          {
            title: i18n.t('projectVersionData.shortName'),
            data: data.shortName
          },
        );
      }

      result.push(
        {
          title: i18n.t('Input stage title'),
          data: getDict(self.props.dict.stage, data.stageId)
        },
        {
          title: i18n.t('Input type title'),
          data: getDict(self.props.dict.projectTypes, data.typeId)
        },
        {
          title: i18n.t('projectVersion.cmsOrder'),
          data: data.cmsOrder
        },
        {
          title: i18n.t('projectVersion.investDirection'),
          data: data.investDirectionName
        },
        {
          title: i18n.t('projectVersion.investProgram'),
          data: data.investProgramName
        },
        {
          title: i18n.t('Start date'),
          data: formatDate(data.dateStart)
        },
        {
          title: i18n.t('End date'),
          data: formatDate(data.dateEnd)
        },
        {
          title: i18n.t('projectVersion.segment'),
          data: getDict(dictTreeToDict(self.props.dict.segment), data.segmentId)
        },
        {
          title: i18n.t('projectVersion.externalCustomer'),
          data: data.externalCustomer?.label
        },
        {
          title: i18n.t('projectVersion.segmentExecutor'),
          data: getDict(dictTreeToDict(self.props.dict.segment), data.segmentExecutorId)
        },
      );

      if (['PROJECT', "PROGRAM"].includes(projectTypeCode)) {
        result.push({
          title: "Портфель",
          data: data.portfolioInfo?.name
        });
      }

      if (['PROJECT'].includes(projectTypeCode)) {
        result.push({
          title: "Программа",
          data: data.programInfo?.name
        });
      }

      if (['PROJECT', 'PROGRAM'].includes(projectTypeCode)) {
        result.push({
          title: i18n.t('projectVersion.parentProjectId'),
          data: data.parentProjectInfo?.name
        });
      }

      result.push(
        {
          title: i18n.t('Input perimeter title'),
          data: data.perimeter
        },
        {
          title: i18n.t('Input manager title'),
          data: getDict(self.props.dict.manager, data.managerId)
        },
        {
          title: i18n.t('Input geography title'),
          data: data.geography
        },
        {
          title: i18n.t('Input limits title'),
          data: data.limits
        },
        {
          title: i18n.t('projectVersion.partiesConcerned'),
          data: data.partiesConcerned
        },
        {
          title: i18n.t('projectVersion.analystCorporateOffice'),
          data: get(data, 'analystCorporateOffice.displayName')
        },
        {
          title: i18n.t('projectVersion.analyst'),
          data: get(data, 'analyst.displayName')
        },
        {
          title: i18n.t('projectVersion.financeController'),
          data: get(data, 'financeController.displayName')
        },
        {
          title: i18n.t('projectVersion.contractId'),
          data: data.contractName
        },
      );

      return result;
    }
  }
]);

export default AgreementGeneralBlock;
