import React, { useEffect } from "react";
import Title from "@/pages/CreateProject/CloseReport/components/Title";
import { decLoadCount, incLoadCount } from "@/actions/actionHelper";

const PdfPage = ({ isLoad, frames, pages, type, title }) => {

  const print = async () => {
    if (!isLoad) {
      return;
    }

    incLoadCount();
    const interval = setTimeout(() => {
      decLoadCount();
      window.print();
    }, 10000);

    return () => clearInterval(interval);
  }

  useEffect(() => {
    print();
  }, [isLoad]);


  if (!isLoad) {
    return null;
  }

  return (
    <div className="presentation__container pdf">
      {frames.map(({ component: Component, data, parentId }, i) => (
        <div key={i} className="break-after presentation__body__container">
          <div className="presentation__body__item presentation__wrapper">
            <Component key={parentId}
                       projectId={parentId}
                       data={data}
                       pages={pages}
                       type={type}
                       isPdf={true} />
          </div>
        </div>
      ))}
      <Title className="d-none">
        {title}
      </Title>
    </div>
  );
}

export default PdfPage;