import { inRange } from 'lodash';
import qs from 'query-string';
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useCurrentPage = ({ maxLength }): [number, any] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentFrameNumber, setCurrentFrameNumber] = useState(+(searchParams.get("page") || 0));

  const setCurrentFrameNumberGuard = useCallback((func: (oldValue: number) => number) => {
    setCurrentFrameNumber((oldValue: number) => {
      const newValue = func(oldValue);
      return inRange(newValue, maxLength) ? newValue : oldValue;
    });
  }, [setCurrentFrameNumber, maxLength]);

  const handleUserKeyPress = useCallback((e) => {
    const key = e.code;

    if (key === 'ArrowLeft') {
      setCurrentFrameNumberGuard(oldValue => oldValue - 1);
    }

    if (key === 'ArrowRight' || key === 'Space') {
      setCurrentFrameNumberGuard(oldValue => oldValue + 1);
    }

  }, [setCurrentFrameNumberGuard]);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  useEffect(() => {
    const realPage = +(searchParams.get("page") || 0);
    if (currentFrameNumber !== realPage) {
      setSearchParams(qs.stringify({
        ...getParams(searchParams),
        page: currentFrameNumber
      }));
    }
  }, [currentFrameNumber, setSearchParams]);

  useEffect(() => {
    const realPage = +(searchParams.get("page") || 0);
    if (currentFrameNumber !== realPage) {
      setCurrentFrameNumber(() => realPage);
    }
  }, [searchParams]);

  return [currentFrameNumber, setCurrentFrameNumberGuard];
};

export const getParams = (searchParams) => {
  const result = {};
  for (const [key, value] of searchParams.entries()) {
    result[key] = value;
  }

  return result;
};

export const useFontSize = (defaultValue = 14): [number, (item: number) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [fontSize, setFontSize] = useState(+(searchParams.get("fontSize") || defaultValue));

  useEffect(() => {
    setSearchParams(qs.stringify({
      ...getParams(searchParams),
      fontSize: fontSize
    }), {
      replace: true
    });
  }, [fontSize]);

  return [fontSize, setFontSize];
};
