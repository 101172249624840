import i18n from "i18next";
import { getDictObj, getRound } from '@/utils'
import { sortBy } from 'lodash';

const AgreementBudgetDetailBlock = (self) => ([
    {
      multiple: true,
      generator: (data) => {
        if (!data.budget) {
          return []
        }

        return sortBy(data.budget, 'r12Code').map((item, i) => {
          const type = getDictObj(self.props.dict.budgetSourceType, item.typeId);
          return {
            title: `Источник финансирования ${type?.name} ${i + 1}`,
            data: [
              {
                title: 'Вводится вручную',
                data: item.isManualInput
              },
              {
                title: 'Источник внесен справочно для мониторинга',
                data: item.isForInfoOnly
              },
              {
                title: 'Номер портфеля проектов в R12',
                data: item.r12Code
              },
              {
                title: i18n.t(`projectBudgetSourceWeb.okbFreeFunds`),
                data: getRound(item.okbFreeFunds)
              },
              {
                title: i18n.t(`projectBudgetSourceWeb.ddsFreeFunds`),
                data: getRound(item.ddsFreeFunds)
              },
              {
                title: 'ЦФО',
                data: ((data) => {
                  const cfo = getDictObj(self.props.dict.cfo, data.cfoId);

                  if (!cfo) {
                    return;
                  }

                  return `${cfo.code} - ${cfo.name}`
                })(item)
              },
              {
                title: 'Название источника финансирования',
                data: item.name
              },
            ]
          }
        });
      }
    },
])

export default AgreementBudgetDetailBlock;
