import { getBudgetNameTable, getSumBudgets } from '@/pages/CreateProject/Budget/util'
import { getDictByCode, getRound } from '@/utils';

const AgreementCapexBlock = (self: any, typeCode: BudgetSourceType) => ([
  {
    multiple: true,
    generator: (data) => {
      if (!data.budget) {
        return [];
      }

      const budgetSourceType = getDictByCode(self.props.dict.budgetSourceType, typeCode);
      const result = [];
      getSumBudgets(data.budget, data, budgetSourceType?.id).forEach(budget => {
        result.push({
          key: `year_${budget.year}_${typeCode}`,
          title: `Год ${budget.year}`,
          data: [
            {
              key: `okbPlan_${budget.year}`,
              title: `${getBudgetNameTable(budgetSourceType)} (план)`,
              data: getRound(budget.okbPlan, 0)
            },
            {
              key: `okbFact_${budget.year}`,
              title: `${getBudgetNameTable(budgetSourceType)} (факт)`,
              data: getRound(budget.okbFact, 0)
            },
            ...(typeCode === 'CAPEX' ? ([
              {
                key: `ddsPlan_${budget.year}`,
                title: 'ДДС (план)',
                data: getRound(budget.ddsPlan, 0)
              },
              {
                key: `ddsFact_${budget.year}`,
                title: 'ДДС (факт)',
                data: getRound(budget.ddsFact, 0)
              },
              {
                key: `inputPlan_${budget.year}`,
                title: 'ВВОД (план)',
                data: getRound(budget.inputPlan, 0)
              },
              {
                key: `inputFact_${budget.year}`,
                title: 'ВВОД (факт)',
                data: getRound(budget.inputFact, 0)
              },
            ]) : [])
          ]
        })
      })

      return result;
    }
  },
])

export default AgreementCapexBlock;
