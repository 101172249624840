import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { dictTreeToDict, formatDateWithFormat, getDict, getDictObjList, isEmptyValues, isNotEmptyValues } from '@/utils';
import { getPathByType } from '@/utils/project';
import cx from 'classnames';
import { useAppSelector } from '@/utils/typedHooks';
import { CopyIcon } from "@/components/Gantt/elements/Icons";
import { useAddToast } from "@/utils/hooks";
import i18n from "i18next";

export const StatusItem = ({
  className,
  text,
  style,
  wrap = false,
  hoverTitle,
  onClick
}: {
  className?: string
  text: any
  style?: CSSProperties
  wrap?: boolean
  hoverTitle?: string
  onClick?: React.MouseEventHandler<Element>
}) => !isEmptyValues(text) ? (
  <div className={cx("project__status-item", className, { nowrap: !wrap })} style={style} title={hoverTitle} onClick={onClick}>
    {text}
  </div>
) : null;

export const StatusItemSimple = ({
  className,
  type,
  style,
  wrap,
  hoverTitle,
  onClick,
  lowText,
  averageText,
  highText
}: {
  className?: string;
  type: 'LOW' | 'AVERAGE' | 'HIGH';
  style?: CSSProperties;
  wrap?: boolean;
  hoverTitle?: string;
  onClick?: React.MouseEventHandler<Element>;
  lowText?: string;
  averageText?: string;
  highText?: string;
}) => {
  const textDict = {
    LOW: lowText ? lowText : 'Низкая',
    AVERAGE: averageText ? averageText : 'Средняя',
    HIGH: highText ? highText : 'Высокая'
  }
  return <StatusItem className={cx(className, type)}
                     text={textDict[type]}
                     wrap={wrap}
                     style={style}
                     onClick={onClick}
                     hoverTitle={hoverTitle}/>
}

export const StatusItemDict = ({
  id,
  type,
  typeText,
  className,
  color,
  wrap
}: {
  id: number | number[];
  type: string;
  typeText?: string;
  className?: string;
  color?: string;
  wrap?: boolean;
}) => {
  const dict = useAppSelector(({ dict }) => dictTreeToDict(dict[type]));
  const result = getDictObjList(dict, id);

  if (isEmptyValues(result)) {
    return null;
  }

  const code = `${type} ${result.map(item => item.code).join(' ')}`;
  const name = result.map(item => item.name).join(', ');
  const text = isEmptyValues(typeText) ? name : `${typeText}: ${name}`;
  return (
    <StatusItem className={cx(className, code)} text={text} wrap={wrap} style={{ backgroundColor: color }} />
  )
}

export const ProjectIdLink = ({
  projectId,
}: {
  projectId: number
}) => {
  const showToast = useAddToast();

  const onClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    const url = `${window.location.origin}/static/${projectId}`;
    navigator.clipboard.writeText(url);
    showToast('Ссылка на карточку скопирована в буфер обмена', 'COPY_STATIC_ID');
  }

  return (
    <span className='hovered-svg' title='Скопировать ссылку на данную карточку' onClick={onClick}>
      <CopyIcon style={{marginTop: '-2px'}}/>
    </span>
  )
}

class Status extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      items: []
    }
  }

  updateData = () => {
    const { data } = this.props;

    this.setState({
      items: Object.entries(data).map(([key, item]) => {
        const dict = this.props.dict[key];

        if (!dict) {
          return {};
        }

        const dictItem = dict.data.find(d => d.id === item);

        if (!dictItem) {
          return {};
        }

        return {
          name: dictItem.name,
          type: key,
          code: `${key} ${dictItem.code}`,
          value: dictItem.code
        }
      }).filter(v => v.name)
    })
  }

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    let isUpdate = false;

    Object.keys(this.props.dict).forEach(key => {
      if (this.props.dict[key].isLoad !== prevProps.dict[key].isLoad) {
        isUpdate = true
      }
    })

    if (prevProps.data !== this.props.data) {
      isUpdate = true;
    }

    if (isUpdate) {
      this.updateData();
    }
  }

  getSegmentText() {
    if (isEmptyValues(this.props.data.segmentChain)) {
      return null;
    }

    const from = getDict(dictTreeToDict(this.props.dict.segment), this.props.data.segmentChain[0]);
    const to = getDict(dictTreeToDict(this.props.dict.segment), this.props.data.segmentChain[1]);

    if (!from && !to) {
      return null;
    }

    return `${from || '?'} -> ${to || '?'}`
  }

  onClick = (e: React.MouseEvent<Element, MouseEvent>, projectIdElementId) => {
    e.preventDefault();
    if (e.detail === 2) {
      const element = document.getElementById(projectIdElementId);
      const sel = document.getSelection();
      const range = document.createRange();
      range.selectNodeContents(element);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }

  render() {
    const segmentText = this.getSegmentText();
    const projectIdElementId = `projectIdItem${this.props.data.projectId}`;

    return (
      <div className="project__status-container">
        {this.props.data.projectId && (
          <StatusItem
            className="status-adv bold"
            text={(<>ID <span id={projectIdElementId} style={{cursor: 'auto'}} onClick={e => this.onClick(e, projectIdElementId)}>{this.props.data.projectId}</span> <ProjectIdLink projectId={this.props.data.projectId}/></>)}
            onClick={(e) => e.preventDefault()}
          />
        )}
        {this.state.items.map((item, i) => {
          const date = this.props.projectData.dateAgreement || this.props.projectData.dateUpdate;
          let text = item.name;
          if (item.type === 'status' && item.value === 'ARCHIVE' && date) {
            text = `${item.name} от ${formatDateWithFormat(date)}`
          }

          return (
            <StatusItem key={i} className={item.code} text={text} />
          )
        })
        }
        {/*{this.props.data.projectInvestmentCommitteeId && (*/}
        {/*  <Link to={`/${getPathByType(this.props.projectData.projectTypeCode)}/${this.props.data.projectInvestmentCommitteeId}`}*/}
        {/*        className="project__status-item PROJECT_INVESTMENT_COMMITTEE ACTIVE" >*/}
        {/*    ПИК*/}
        {/*  </Link>*/}
        {/*)}*/}
        {/*{this.props.data.steeringCommitteeId && (*/}
        {/*  <Link to={`/${getPathByType(this.props.projectData.projectTypeCode)}/${this.props.data.steeringCommitteeId}`}*/}
        {/*        className="project__status-item STEERING_COMMITTEE ACTIVE" >*/}
        {/*    УК*/}
        {/*  </Link>*/}
        {/*)}*/}
        {this.props.projectData?.agreeStageCode && ['COMMITTEE_FIRST_QUEUE', 'COMMITTEE_SECOND_QUEUE',
            'COMMITTEE_VOTE', 'COMMITTEE_INTRAMURAL'].includes(this.props.projectData?.agreeStageCode) &&
          <StatusItem className="project__status-item PROJECT_INVESTMENT_COMMITTEE ACTIVE"
                      text={i18n.t(`committeeStage.${this.props.projectData?.agreeStageCode}`)} />
        }
        {isNotEmptyValues(segmentText) && (
          <StatusItem className="status-adv" text={segmentText} hoverTitle="Сегмент заказчика -> Сегмент исполнителя" wrap={true} />
        )}
        {this.props.data.isClosed && (
          <StatusItem className="status-adv CLOSED" text={`Закрыто ${formatDateWithFormat(this.props.projectData.dateClosed)}`} />
        )}
      </div>
    )
  }
}

const mapStateToProp = (state) => ({
  dict: state.dict,
});

export default connect(mapStateToProp, null)(Status);
