import { getRound, parseDate } from '@/utils';

const AgreementOibdaBlock = (self: any) => ([
  {
    multiple: true,
    generator: (data) => {
      if (!data.oibda) {
        return [];
      }

      const oibdaByYear = data.oibda.reduce((acc, item) => {
        const effectiveDate = parseDate(item.effectiveDate);
        const year = effectiveDate.year();
        acc[year] = {
          plan: (acc[year]?.plan || 0) + (item.plan || 0),
          fact: (acc[year]?.fact || 0) + (item.fact || 0),
        }
        return acc;
      }, {});

      const result = [];
      Object.keys(oibdaByYear).forEach(year => {
        result.push({
          key: `year_${year}`,
          title: `Год ${year}`,
          data: [
            {
              key: `plan_${year}`,
              title: `План`,
              data: getRound(oibdaByYear[year].plan, 0)
            },
            {
              key: `fact_${year}`,
              title: `Факт`,
              data: getRound(oibdaByYear[year].fact, 0)
            },
          ]
        })
      })

      return result;
    }
  },
])

export default AgreementOibdaBlock;
