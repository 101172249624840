import SelectInTable from "@/elements/Select/SelectInTable";
import { getRound } from "@/utils";
import React from "react";
import { getYearsFilter, isSkipFiltered, updateListByIndex } from '@/pages/CreateProject/Budget/util'
import moment from "moment";
import i18n from "i18next";
import cx from 'classnames'
import {
  CapitalizationPayrollTableProps, InputNumTab, getDefaultCapitalizationPayroll,
  getNextEffectiveDateFromNow, availYearFromNow, availMonthFromNow
} from "@/pages/CreateProject/Blocks/BudgetPrediction/helper";

const CapitalizationPayrollTable = ({
  data,
  isEdit,
  yearFilter,
  filterChange,
  getSumCol,
  tableHash,
  onUpdate,
}: CapitalizationPayrollTableProps) => {
  const yearsFilter = getYearsFilter(data);

  const addRow = () => {
    onUpdate((data) => {
      return [
          ...data,
          getDefaultCapitalizationPayroll(getNextEffectiveDateFromNow(data))
      ];
    });

    filterChange(-1);
  };

  const deleteRow = (index: number) => {
    onUpdate((data) => {
      const newList = [...data];
      newList.splice(index, 1);

      return newList;
    })
  };

  const handleChangeInput = (i: number, name: string, value: any) => {
    onUpdate((data) => {
        return updateListByIndex(data, i, (item: any) => ({
          ...item,
          [name]: value
        }));
    })
  };

  const handleYearChange = (i: number, value: string | number) => {
    let newDate = moment(data[i]['effectiveDate']).year(+value);
    const curMonth = moment().startOf('months');
    if (newDate.diff(curMonth) < 0) {
      newDate = curMonth;
    }

    handleChangeInput(
      i,
      'effectiveDate',
      newDate.format("YYYY-MM-DD")
    )
  }

  const handleMonthChange = (i: number, value: string | number) => {
    handleChangeInput(
      i,
      'effectiveDate',
      moment(data[i]['effectiveDate']).month(+value - 1).format("YYYY-MM-DD")
    )
  }

  return (
    <div className="flex-space-between">
      <div className={cx("budget-table__wrapper")}>
        <table className="table table-hove table-felix budget-table__container capitalization-payroll-table">
          <thead className="budget-table__head">
            <tr>
              <td className="budget-table__field-head table-mini-select">Год</td>
              <td className="budget-table__field-head table-mini-select month-col">Месяц</td>
              <td rowSpan={2} className="left-border spanned-bottom">ОКВ (тыс. руб.)</td>
              <td rowSpan={2} className="left-border spanned-bottom">ДДС (тыс. руб.)</td>
              <td rowSpan={2} className="left-border mini-cell">
                {isEdit && (
                  <div className="budget-table__button-add">
                    <button className="button_Link_borderNone flex-center" onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      addRow();
                    }}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.8">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.25 12.75V21H12.75V12.75H21V11.25H12.75V3H11.25V11.25H3V12.75H11.25Z"
                            fill="#101828"
                          />
                        </g>
                      </svg>
                    </button>
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td className="table-mini-select">
                <SelectInTable
                  options={yearsFilter}
                  label="Год"
                  placeholder="Год"
                  value={yearFilter}
                  onChange={filterChange}
                  hasError={false}
                  multiple={false}
                />
              </td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {data.map((listItem, i) => {
              if (isSkipFiltered(listItem, yearFilter)) {
                return null;
              }

              const effectiveDate = moment(listItem.effectiveDate);

              return (
                <tr key={listItem.effectiveDate + tableHash + i} className="tableEditRow">
                  <td className="table-mini-select">
                    <SelectInTable
                      options={availYearFromNow(effectiveDate.year())}
                      label="Год"
                      value={+effectiveDate.format("YYYY")}
                      onChange={(e) => handleYearChange(i, e)}
                      disabled={!isEdit}
                      showUndefined
                    />
                  </td>
                  <td className="table-mini-select">
                    <SelectInTable
                      options={availMonthFromNow(effectiveDate.year())}
                      label="Месяц"
                      value={effectiveDate.format("MM")}
                      onChange={(e) => handleMonthChange(i, e)}
                      disabled={!isEdit}
                      widthInput={100}
                      widthMenu={102}
                    />
                  </td>
                  <td className="left-border">
                    <InputNumTab className="input-edit-felix"
                      value={getRound(listItem.okb)}
                      placeholder={i18n.t('budget no data')}
                      onChange={(val) => handleChangeInput(i, 'okb', val)}
                      readOnly={!isEdit} />
                  </td>
                  <td className="left-border">
                    <InputNumTab className="input-edit-felix"
                                 value={getRound(listItem.dds)}
                                 placeholder={i18n.t('budget no data')}
                                 onChange={(val) => handleChangeInput(i, 'dds', val)}
                                 readOnly={!isEdit} />
                  </td>
                  <td className="left-border mini-cell">
                    {isEdit && data.length > 0 && (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          deleteRow(i);
                        }}
                        className="budget_remove_button button_Link_borderNone_delete button-visible"
                      >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M3 11.25H21V12.75H3V11.25Z" fill="#101828" />
                        </svg>
                      </span>
                    )}
                  </td>
                </tr>
              )
            }
            )}
            <tr>
              <td>Итого</td>
              <td></td>
              <td>{getRound(getSumCol('okb'))}</td>
              <td>{getRound(getSumCol('dds'))}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default CapitalizationPayrollTable;

