import { getDict, formatDate, dictTreeToDict, getDictObj } from '@/utils'
import i18n from "i18next";
import { compact } from 'lodash';

const quantitativeIndicators = (self) => ([
  {
    multiple: true,
    generator: (data) => {
      if (!data.quantitativeIndicators) {
        return []
      }

      return data.quantitativeIndicators.map((item) => ({
        key: item.staticId,
        title: (_, i) => `Показатель ${i + 1}`,
        data: [
          {
            key: 'categoryId',
            title: i18n.t('businessCaseWeb.categoryId'),
            data: getDict(self.props.dict.businessCaseCategory, item.categoryId)
          },
          {
            key: 'indexId',
            title: i18n.t('businessCaseWeb.indexId'),
            data: getDict(dictTreeToDict(self.props.dict.businessCaseIndex), item.indexId)
          },
          {
            key: 'baseIndexDescription',
            title: i18n.t('businessCaseWeb.baseIndexDescription'),
            data: item.baseIndexDescription
          },
          {
            key: 'amountBenefit',
            title: i18n.t('businessCaseWeb.amountBenefit'),
            data: item.amountBenefit
          },
          {
            key: 'strategicInitiative',
            title: i18n.t('businessCaseWeb.strategicInitiative'),
            data: item.strategicInitiative
          },
          {
            key: 'methodMeasurement',
            title: i18n.t('businessCaseWeb.methodMeasurement'),
            data: item.methodMeasurement
          },
          {
            key: 'dateBenefit',
            title: i18n.t('businessCaseWeb.dateBenefit'),
                      data: formatDate(item.dateBenefit)
          },
          {
            key: 'beneficiarySegment',
            title: i18n.t('businessCaseWeb.beneficiarySegment'),
            data: getDict(dictTreeToDict(self.props.dict.segment), item.beneficiarySegment)
          },
          {
            key: 'responsible',
            title: i18n.t('businessCaseWeb.responsible'),
            data: item.responsible.displayName
          },
          {
            key: 'beneficiary',
            title: i18n.t('businessCaseWeb.beneficiary'),
            data: item.beneficiary.displayName
          },
          {
            key: 'statusId',
            title: i18n.t('businessCaseWeb.statusId'),
            data: getDict(self.props.dict.businessCaseStatus, item.statusId)
          },
          ...(getDictObj(self.props.dict.businessCaseStatus, item.statusId)?.code !== 'PLANNED' ? [
            {
              key: 'statusEndDescription',
              title: i18n.t('businessCaseWeb.statusEndDescription'),
              data: item.statusEndDescription
            },
            {
              key: 'dateActualAssessment',
              title: i18n.t('businessCaseWeb.dateActualAssessment'),
                          data: formatDate(item.dateActualAssessment)
            },
          ] : []),
          ...(item.isOpexIndicator ? (item.opexIndicators.map((itemIndicator, i) => {
            const businessCase = getDictObj(self.props.dict.businessCaseOpexBusinessPrc, itemIndicator.businessCaseId);
            const shpp = getDictObj(self.props.dict.businessCaseOpexShpp, itemIndicator.shppId);
            const r12 = getDictObj(self.props.dict.businessCaseOpexR12, itemIndicator.r12Id);

            return {
              key: `${i}`,
              title: `OPEX ${i + 1}`,
              data: compact([
                businessCase ? `${businessCase?.code} - ${businessCase?.name}` : undefined,
                shpp ? `${shpp?.code} - ${shpp?.name}` : undefined,
                r12 ? `${r12?.code} - ${r12?.name}` : undefined,
              ]).join('\n')
            };
          })) : [])

        ]
      }))
    }
  },
])

export default quantitativeIndicators;
