import './style.scss';

import cx from "classnames";
import i18n from "i18next";
import { get, isEmpty } from 'lodash';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import ButtonRt from "@/components/Helper/ButtonRt";
import Spoiler from "@/components/Spoiler";
import { UserRight } from "@/config/const";
import Tooltip from "@/elements/Tooltip";
import BudgetContractedTable from "@/pages/CreateProject/Blocks/BudgetPrediction/ContractedTable";
import BudgetDifferenceTable from "@/pages/CreateProject/Blocks/BudgetPrediction/DifferenceTable";
import BudgetPredictionInputTable from "@/pages/CreateProject/Blocks/BudgetPrediction/PredictionInputTable";
import ReworkComment from "@/pages/CreateProject/Blocks/BudgetPrediction/ReworkComment";
import { getDefaultBudgetPredictionInput } from "@/pages/CreateProject/Blocks/BudgetPrediction/helper";
import BudgetPredictionOrderTable from "@/pages/CreateProject/Blocks/PredictionOrderTable";
import { getRoundFormat, isSkipFiltered, sortDatedBudget } from '@/pages/CreateProject/Budget/util';
import { getProjectTypeText } from "@/pages/Dashboard2/ProjectWorkList";
import service from "@/services";
import { getDictByCode, getDictObj, prevent } from "@/utils";
import TextArea from "@/elements/TextArea";
import { useStateWithKey } from "@/utils/hooks";
import { useClockManager } from '@/utils/clockmanager';

const dataLoader = (id: number[]) => service.get(`/budget/prediction/project/${id}`);
const dataUpdater = (id: number[], props = {}) => service.post(`/budget/prediction/project/${id}/update`, props);
const dataCoordinate = (id: number[]) => service.get(`/budget/prediction/project/${id}/coordinate`);
const dataAccept = (id: number[], props = {}) => service.post(`/budget/prediction/project/${id}/accept`, props);
const dataAcceptEditing = (id: number[]) => service.get(`/budget/prediction/project/${id}/acceptEditing`);

const BudgetPrediction = ({ projectData, checkRight, dict, works }: {
  projectData: any;
  checkRight: Function,
  dict: any,
  works: any,
}) => {
  const clockManager = useClockManager();
  const [isShowRework, setIsShowRework] = useState(false);
  const [diffSpoilerState, setDiffSpoilerState] = useState(false);
  const [accentRequiredOnAgree, setAccentRequiredOnAgree] = useState(false);

  const [state, setState] = useState({
    contractedYearFilter: -1,
    predictionInputYearFilter: -1,
    differenceTableHash: Math.random(),
    contractedTableHash: Math.random(),
    predictionInputTableHash: Math.random(),
  });

  const [data, setDataByKey, setData] = useStateWithKey({
    statusId: null,
    acceptDate: null,
    acceptUser: null,
    editDate: null,
    editUser: null,
    reworkComment: null,
    differenceComment: null,
    financeController: null,
    differenceList: [],
    contractedList: [],
    inputList: [],
    consolidateList: [],
  } as BudgetPredictionData);

  const getContractedSumCol = (colName: string, isFilter: boolean = true, dataLocal = data) => {
    return dataLocal.contractedList.reduce((sum, item) => {
      if (isFilter && isSkipFiltered(item, state.contractedYearFilter)) {
        return sum;
      }

      return sum + +(item[colName] || 0);
    }, 0);
  };

  const getPredictionInputSumCol = (colName: string, isFilter: boolean = true, dataLocal = data) => {
    return dataLocal.inputList.reduce((sum, item) => {
      if (isFilter && isSkipFiltered(item, state.predictionInputYearFilter)) {
        return sum;
      }

      return sum + +(item[colName] || 0);
    }, 0);
  };

  const contractedFilterChange = (val: number) => {
    setState({
      ...state,
      contractedYearFilter: val
    });
  };

  const predictionInputFilterChange = (val: number) => {
    setState({
      ...state,
      predictionInputYearFilter: val
    });
  };

  const onSave = () => {
    dataUpdater(projectData.projectId, {
      differenceComment: data.differenceComment,
      inputList: data.inputList,
    }).then(loadData);
  };

  const onCoordinate = async () => {
    await dataUpdater(projectData.projectId, {
      differenceComment: data.differenceComment,
      inputList: data.inputList,
    });

    dataCoordinate(projectData.id)
      .catch(() => {
        setDiffSpoilerState(false);
        setDiffSpoilerState(true);
        setAccentRequiredOnAgree(true);
      })
      .then(loadData);
  };

  const onAccept = () => {
    const request = {
      statusId: get(getDictByCode(dict.budgetPredictionStatus, 'RELEASE'), 'id'),
    };

    dataAccept(projectData.projectId, request)
      .then(loadData).catch(reason => console.log(reason));
  };

  const onAcceptEditing = () => {
    dataAcceptEditing(projectData.projectId)
      .then(loadData);
  };

  const onRework = () => {
    setIsShowRework(true);
  };

  const onReworkComment = (comment) => {
    const request = {
      statusId: get(getDictByCode(dict.budgetPredictionStatus, 'REWORK'), 'id'),
      comment,
    };

    dataAccept(projectData.projectId, request)
      .then(loadData);
  };

  const loadData = () => {
    if (!projectData.id) {
      return;
    }

    dataLoader(projectData.id).then(data => {
      if (isEmpty(data.inputList)) {
        data.inputList.push(getDefaultBudgetPredictionInput());
      }

      data.contractedList = data.contractedList.sort(sortDatedBudget);
      data.inputList = data.inputList.sort(sortDatedBudget);

      setData(data);
    });
  };

  useEffect(() => {
    if (projectData.id) {
      loadData();
    }
  }, [projectData.id]);

  const exportOrdersXlsx = () => {
    return service.postDownload(`/budget/prediction/project/${projectData.projectId}/consolidate/xls`);
  };

  const projectBase = i18n.t('base');

  const isEditBudgetPredictionRight = checkRight(UserRight.EDIT_BUDGET_PREDICTION);
  const isPreliminaryAcceptRight = checkRight(UserRight.PRELIMINARY_ACCEPT_BUDGET_PREDICTION);
  const isAcceptRight = checkRight(UserRight.ACCEPT_BUDGET_PREDICTION);
  const isForceAcceptRight = checkRight(UserRight.FORCE_ACCEPT_BUDGET_PREDICTION);
  const isReworkRight = checkRight(UserRight.REWORK_BUDGET_PREDICTION);
  const isBudgetPredictionCoordinatorInTeam = !!projectData?.team?.BUDGET_PREDICTION_COORDINATOR;
  const isCanForceAccept = isForceAcceptRight && !isBudgetPredictionCoordinatorInTeam;

  const projectTypeText = getProjectTypeText(projectData.projectTypeCode);
  const status = getDictObj(dict.budgetPredictionStatus, data.statusId) || { name: null, code: null };
  const editDate = clockManager.formatDateTime(data.editDate);
  const editUser = data.editUser?.displayName;
  const acceptDate = clockManager.formatDateTime(data.acceptDate);
  const acceptUser = data.acceptUser?.displayName;

  const editDateNotNull = data.editDate ? moment(data.editDate) : moment();
  const isCoordinationAllowed = ['EDITING', 'REWORK'].includes(status.code)
    && (!data.acceptDate || editDateNotNull.diff(moment(data.acceptDate), 'seconds') > 0);

  const acceptStr = (status.code === 'RELEASE' && (acceptDate || acceptUser) ? `, ${acceptUser} ${acceptDate}` : '');
  const editStr = editDate || editUser ? `Последние изменения: ${editUser} ${editDate}` : '';

  const isCoordination = status.code === 'COORDINATION';
  const isPreliminaryCoordination = status.code === 'PRELIMINARY_COORDINATION';

  const isExecutorBti = !isEmpty(projectData.segmentExecutorId) && !!projectData.segmentExecutorId
    .map(id => dict.segment?.data?.find(s => s.value === id))
    .find(segment => segment?.code === 'BTI');

  const budgetDifferenceCommentRequired = () => {
    if (!data.differenceList.find(value => value.prediction === 0 && value.difference !== 0
      || Math.abs(value.difference * 100 / value.prediction) > 1)) {
      return false;
    }
    return accentRequiredOnAgree ? 'red' : 'default'
  };

  const getStatusName = () => {
    if (isBudgetPredictionCoordinatorInTeam && isCoordination) {
      return 'На согласовании у Финансового Контролера'
    }
    return status.name;
  }

  return (
    <React.Fragment>
      {isExecutorBti && data.statusId && (
        <Spoiler
          title={`Прогноз бюджета`}
          titleClassName="bold wrapper-option"
          className="input-title__container"
          defaultState={true}
        >
          <div className="budget-prediction-form">
            <div className="marginBottom-20">
              <div className="input-title__description">
                Статус: <span className={cx('prediction-status', status.code)}>{getStatusName()}</span>{acceptStr} {status.code === 'REWORK' && data.reworkComment && <Tooltip text={data.reworkComment} />}
                <br />
                {editStr}
              </div>
            </div>
            <Spoiler
              title={`Сравнение бюджета и прогноза по CAPEX (тыс. руб.)`}
              titleClassName="bold"
              className="input-title__container wrapper-option"
              defaultState={diffSpoilerState}
            >
              <BudgetDifferenceTable
                data={data}
                tableHash={state.differenceTableHash}
              />
              <TextArea
                required={budgetDifferenceCommentRequired()}
                titleClassName='mt-2'
                label="Комментарий"
                value={data.differenceComment}
                onChange={e => setDataByKey('differenceComment', e.target.value)}
                disabled={!isEditBudgetPredictionRight || isCoordination}
              />
            </Spoiler>
            <Spoiler
              title={`Законтрактованный бюджет ${projectTypeText} (${getRoundFormat(getContractedSumCol('okb'), '0')} тыс. руб.)`}
              titleClassName="bold"
              className="input-title__container wrapper-option"
              defaultState={false}
            >
              <BudgetContractedTable
                yearFilter={state.contractedYearFilter}
                filterChange={contractedFilterChange}
                data={data}
                getSumCol={getContractedSumCol}
                tableHash={state.contractedTableHash}
              />
            </Spoiler>

            <Spoiler
              title={`Прогноз бюджета по Ввод (${getRoundFormat(getPredictionInputSumCol('prediction'), '0')} тыс. руб.)`}
              titleClassName="bold"
              className="input-title__container wrapper-option"
              defaultState={false}
            >
              <BudgetPredictionInputTable
                isEdit={isEditBudgetPredictionRight && !isCoordination}
                yearFilter={state.predictionInputYearFilter}
                filterChange={predictionInputFilterChange}
                data={data}
                getSumCol={getPredictionInputSumCol}
                tableHash={state.predictionInputTableHash}
                onUpdate={setData}
              />
            </Spoiler>

            <Spoiler
              title={`Свод документов по блокам работ`}
              titleClassName="bold wrapper-option marginBottom-10"
              className="input-title__container"
              defaultState={false}
            >
              <button className="link dashboard__card-title__reset marginLeft-0" onClick={prevent(exportOrdersXlsx)}>
                Выгрузить в Excel
              </button>
              <BudgetPredictionOrderTable
                data={data.consolidateList}
                projectBase={projectBase}
                projectId={projectData.id}
                isShowRemove={false}
                withWork={true}
                works={works}
              />
            </Spoiler>

            <div className="wrapper-option marginBottom-20 report-lesson__buttons button-group">
              {!(isCoordination || isPreliminaryCoordination) && isEditBudgetPredictionRight && (
                <>
                  <ButtonRt type="mini" onClick={onSave}>Сохранить</ButtonRt>
                  <ButtonRt type="outline mini" onClick={loadData}>Отменить</ButtonRt>
                  {isCoordinationAllowed && !isCanForceAccept &&
                    <ButtonRt type="mini" onClick={onCoordinate}>Отправить на согласование</ButtonRt>
                  }
                  {isCoordinationAllowed && isCanForceAccept &&
                    <ButtonRt type="mini" onClick={onAcceptEditing}>Согласовать</ButtonRt>
                  }
                </>
              )}
              {isCoordination && (
                <>
                  {isAcceptRight &&  (
                    <ButtonRt type="mini" onClick={onAccept}>Согласовать</ButtonRt>
                  )}
                  {isReworkRight && (
                    <ButtonRt type="outline mini animation-disable" onClick={onRework}>На доработку</ButtonRt>
                  )}
                </>
              )}
              {isPreliminaryCoordination && (
                <>
                  {isPreliminaryAcceptRight && (
                    <ButtonRt type="mini" onClick={onAccept}>Согласовать</ButtonRt>
                  )}
                  {isReworkRight && (
                    <ButtonRt type="outline mini animation-disable" onClick={onRework}>На доработку</ButtonRt>
                  )}
                </>
              )}
            </div>

            <ReworkComment isShow={isShowRework}
              onOk={onReworkComment}
              hide={() => setIsShowRework(false)} />
          </div>
        </Spoiler>
      )}
    </React.Fragment>
  );
};

export default BudgetPrediction;